@use './colors.scss' as *;
@use './dimensions.scss' as *;

.content-group {
    .content {
        display: block;

        &:not(:last-child) {
            margin-bottom: 32px;
        }

        .remove-margin {
            margin: 0px !important;
        }

        .steel-calculation-method-dropdown {
            width: calc(75% - 6px);
        }

        .concrete-calculation-method-dropdown {
            width: calc(75% - 6px);
        }

        &.fixed-inputs {
            margin-bottom: 16px;

            > .content-controls {
                margin-bottom: 0;
                flex-wrap: wrap;

                > .control, > .control-row {
                    max-width: calc(25% - 32px);
                    margin-bottom: 16px;
                    flex: 0 0 25%;
                }
            }

            > .radio-button-control {
                margin: 10px 0;
            }
        }

        &.fixed-inputs-50 > .content-controls {
            margin-bottom: 0;

            > .control, > .control-row {
                max-width: calc(50% - 32px);
                margin-bottom: 16px;
            }
        }

        &.design-region > .content-controls {

            > .local-steel-material-disclaimer {
                flex: 1;
                background: $color-light-gray;
                color: $color-eggplant;
                border: 1px solid $color-medium-gray;
                padding: 10px;
                margin: 10px 26px 0 26px;
            }
        }

        .content-controls {
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            margin-left: -16px;
            margin-right: -16px;
            margin-bottom: 16px;

            .input {
                width: 100%;
            }

            .control {
                width: 100%;
                margin: 0 16px;
            }

            .control-design-standard-method {
                margin: 0;

                label {
                    padding: 0 0 16px 0;
                }
            }

            .control-row {
                width: 100%;
                margin: 0 16px;
                min-width: 0;
            }

            .text-box .text-box-container .input {
                &.postfix, &.data {
                    max-width: none;
                }
            }

            .design-type-status {
                p {
                    margin: 0;
                    padding: 0;

                    &.error {
                        color: $color-hilti;
                    }
                }
            }

            .structural-calculation-software-checkbox {
                margin: 0 16px;
            }
        }

        .content-buttons {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            text-align: left;
            margin-top: 34px;

            .button {
                margin: 0 2px;

                &:last-child {
                    margin-right: 16px;
                }
            }
        }
    }

    .calculation {

        .content-controls {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
        }

        .design-standard-note {
            margin-top: 6px;
            margin-left: 2px;
            margin-right: 2px;
            color: $color-gray;
        }

        .design-method-note {
            margin-top: 6px;
            margin-left: 2px;
            margin-right: 2px;
            margin-bottom: 16px;
            color: $color-gray;
        }

        .sub-title {
            margin: 0 0 16px 0;
        }

        .data-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            .name-container {
                display: flex;
                flex-direction: column;
                flex: 1;

                .description {
                    color: $color-gray;
                }
            }
        }

        .column {
            &:last-child {
                &.separator {
                    border-right: none;
                }
            }

            &.separator {
                margin: -16px 0;
                padding: 16px 16px;
                border-right: 1px solid $color-metal;
            }
        }
    }
}

.title-strike {
    margin-bottom: 16px;
    margin-top: 0px;
    font-weight: 700;
    color: $color-iron;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        top: 50%;
        margin-left: 8px;
        border-bottom: $separator-line-height solid $color-metal;
    }
}

.control-label {
    color: $color-0x887f6e;
    padding-bottom: 4px;
    text-overflow: ellipsis;
    margin-bottom: 0px;
}

.truncate-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}