import { SortablejsModule } from 'nxt-sortablejs';
import Sortable, { Options } from 'sortablejs';

import {
    AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, OnDestroy,
    OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import {
    NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip
} from '@ng-bootstrap/ng-bootstrap';
import { Update } from '@profis-engineering/gl-model/base-update';
import { LoadsVisibilityInfo } from '@profis-engineering/gl-model/gl-model';
import {
    CheckboxButtonItem, CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import {
    IMainMenuComponent, IMenu
} from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    BaseControl, NavigationTabWidth
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { IDesignInfo } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    INotificationsComponentInput, INotificationScopeCheck, NotificationType
} from '@profis-engineering/pe-ui-common/entities/notifications';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    MenuType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    IDesignTemplateDocument
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { IntroJs } from '@profis-engineering/pe-ui-common/services/tour.common';

import { environment } from '../../../environments/environment';
import { CollapsingControls } from '../../collapsing-controls';
import { Command, commandFromService } from '../../command';
import {
    CloseOutsideNgbDropdownDirective
} from '../../directives/close-outside-ngb-dropdown.directive';
import { L10nPipe } from '../../pipes/l10n.pipe';
import { ApplicationProviderService } from '../../services/application-provider.service';
import { ApprovalsService } from '../../services/approval.service';
import { DataService } from '../../services/data.service';
import {
    DesignDetails, DesignService, designTypes, designTypeSwitch, LoadCombination, PropertyIdValue,
    PunchDesignDetails, PunchLoadCombination, ScopeCheckSeverity, StrengthDesignDetails, ZoneLoad
} from '../../services/design.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { RegionOrderService } from '../../services/region-order.service';
import { RoutingService } from '../../services/routing.service';
import { SpApiService } from '../../services/sp-api.service';
import { TourService } from '../../services/tour.service';
import { TrackingDetails, TrackingService } from '../../services/tracking.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { InternalDesign, UserService } from '../../services/user.service';
import { getSpriteAsIconStyle, includeSprites, Sprite } from '../../sprites';
import { Model, PartialModel, ScreenshotSettings } from '../../web-gl/gl-model';
import { PunchPartialModel } from '../../web-gl/punch-gl-model';
import { StrengthPartialModel } from '../../web-gl/strength-gl-model';
import { DesignSectionComponent } from '../design-section/design-section.component';
import {
    GlModelComponent, GlModelProps, PunchGlModelComponent, StrengthGlModelComponent
} from '../gl-model/gl-model.component';
import { LoadsPunchComponent } from '../loads/loads-punch.component';
import { LoadsStrengthComponent } from '../loads/loads-strength.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import {
    ProductSelectionComponent, ProductSelectionInput
} from '../product-selection/product-selection.component';
import {
    UtilizationPanelComponent
} from '../utilizations/utilization-panel/utilization-panel.component';
import {
    ZoneUtilizationPanelComponent
} from '../utilizations/zone-utilization-panel/zone-utilization-panel.component';

const enum StrengthDisplayOption {
    Zones = 1,
    ZonesDimensions = 2,
    ConcreteDimensions = 3,
    TransparentConcrete = 4,
    AnchorSpacingDimensions = 5,
    AnchorEdgeDistanceDimensions = 6,
    ZonesNumbering = 7,
}

function getStrengthDisplayOptionName(strengthDisplayOption: StrengthDisplayOption): keyof typeof StrengthDisplayOption {
    switch (strengthDisplayOption) {
        case StrengthDisplayOption.AnchorEdgeDistanceDimensions:
            return 'AnchorEdgeDistanceDimensions';
        case StrengthDisplayOption.AnchorSpacingDimensions:
            return 'AnchorSpacingDimensions';
        case StrengthDisplayOption.ConcreteDimensions:
            return 'ConcreteDimensions';
        case StrengthDisplayOption.TransparentConcrete:
            return 'TransparentConcrete';
        case StrengthDisplayOption.Zones:
            return 'Zones';
        case StrengthDisplayOption.ZonesDimensions:
            return 'ZonesDimensions';
        case StrengthDisplayOption.ZonesNumbering:
            return 'ZonesNumbering';
    }
}

const enum PunchDisplayOption {
    ConcreteDimensions = 3,
    TransparentConcrete = 4,
    CriticalPerimeter = 10,
    StrengtheningElementSpacingDimensions = 11,
    StrengtheningElementEdgeDistanceDimensions = 12,
    OpeningNumbering = 13,
    OpeningDimensions = 14,
}

function getPunchDisplayOptionName(punchDisplayOption: PunchDisplayOption): keyof typeof PunchDisplayOption {
    switch (punchDisplayOption) {
        case PunchDisplayOption.ConcreteDimensions:
            return 'ConcreteDimensions';
        case PunchDisplayOption.CriticalPerimeter:
            return 'CriticalPerimeter';
        case PunchDisplayOption.TransparentConcrete:
            return 'TransparentConcrete';
        case PunchDisplayOption.StrengtheningElementSpacingDimensions:
            return 'StrengtheningElementSpacingDimensions';
        case PunchDisplayOption.StrengtheningElementEdgeDistanceDimensions:
            return 'StrengtheningElementEdgeDistanceDimensions';
        case PunchDisplayOption.OpeningNumbering:
            return 'OpeningNumbering';
        case PunchDisplayOption.OpeningDimensions:
            return 'OpeningDimensions';
    }
}

@Component({
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [
        NgbTooltip,
        NgbDropdown,
        CloseOutsideNgbDropdownDirective,
        NgbDropdownToggle,
        NgbDropdownMenu,
        GlModelComponent,
        LoadsStrengthComponent,
        LoadsPunchComponent,
        DesignSectionComponent,
        SortablejsModule,
        ZoneUtilizationPanelComponent,
        UtilizationPanelComponent,
        NotificationsComponent,
        L10nPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('mainMenuRef')
    public mainMenuComponentElementRef!: ElementRef<IMainMenuComponent>;

    @ViewChild('glModelRef')
    public glModelComponent!: GlModelComponent;

    public glModel!: Pick<GlModelProps,
        'continuousRender' |
        'model' |
        'onFontsLoaded' |
        'onSelectTab' |
        'onZoom' |
        'propertyChange'
    >;
    public CollapsingControls: Record<keyof typeof CollapsingControls, CollapsingControls> = {
        DesignResults: CollapsingControls.DesignResults,
        NotificationsDesign: CollapsingControls.NotificationsDesign
    };
    public strengthDesignTypeId = designTypes.strength.id;
    public punchDesignTypeId = designTypes.punch.id;

    public sortableMenu3DRightOptions!: Options;
    public UnitPercent = UnitType.percent;
    public displayOptionsCheckbox!: Pick<CheckboxButtonProps, 'selectedValues' | 'items'>;
    public notificationComponentInputs!: INotificationsComponentInput;

    public commonRegion!: CommonRegion;
    public hideLeftMenu = false;
    public hideRightMenu = false;
    public rightSideLoaded = false;
    public modelViewZoom = 50;

    public designDetails!: DesignDetails;
    public trackingDetails!: TrackingDetails;

    private designDetailsHistory: DesignDetails[] = [];
    private designDetailsHistoryIndex = -1;

    private userLogout = false;
    private openedVirtualTour?: IntroJs;

    public loadCombinations!: LoadCombination[];
    public punchLoadCombinations!: PunchLoadCombination[];

    public design!: InternalDesign;

    constructor(
        public localizationService: LocalizationService,
        private readonly userService: UserService,
        private readonly modalService: ModalService,
        private readonly applicationProviderService: ApplicationProviderService,
        private readonly routingService: RoutingService,
        private readonly tourService: TourService,
        private readonly menuService: MenuService,
        private readonly designService: DesignService,
        private readonly dataService: DataService,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private readonly userSettingsService: UserSettingsService,
        private readonly spApiService: SpApiService,
        private readonly translationFormatService: TranslationFormatService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly regionOrderService: RegionOrderService,
        private readonly favoritesService: FavoritesService,
        private readonly approvalsService: ApprovalsService,
        private readonly trackingService: TrackingService
    ) {
        this.beforeLogout = this.beforeLogout.bind(this);
        this.openDesignSettings = this.openDesignSettings.bind(this);
        this.openSaveAsTemplate = this.openSaveAsTemplate.bind(this);
        this.startTour = this.startTour.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.resize3dAfterUI = this.resize3dAfterUI.bind(this);
        this.openGeneralNotes = this.openGeneralNotes.bind(this);
        this.menuOpened = this.menuOpened.bind(this);
        this.hiltiDataPrivacyUrlOpened = this.hiltiDataPrivacyUrlOpened.bind(this);
        this.regionLinkOpened = this.regionLinkOpened.bind(this);
        this.tabSelected = this.tabSelected.bind(this);
        this.propertyChange = this.propertyChange.bind(this);
        this.resize3d = this.resize3d.bind(this);
        this.createScreenshot3D = this.createScreenshot3D.bind(this);
        this.onBeforeUnloadEvent = this.onBeforeUnloadEvent.bind(this);
    }

    public get strengthDesignDetails(): StrengthDesignDetails {
        if (this.designDetails.designTypeId != designTypes.strength.id) {
            throw new Error('StrengthDesignDetails can only be accessed for designTypeId == strength');
        }

        return this.designDetails as StrengthDesignDetails;
    }

    public get punchDesignDetails(): PunchDesignDetails {
        if (this.designDetails.designTypeId != designTypes.punch.id) {
            throw new Error('PunchDesignDetails can only be accessed for designTypeId == punch');
        }

        return this.designDetails as PunchDesignDetails;
    }

    public get strengthGlModelComponent(): StrengthGlModelComponent {
        if (this.designDetails.designTypeId != designTypes.strength.id) {
            throw new Error('StrengthGlModelComponent can only be accessed for designTypeId == strength');
        }

        return this.glModelComponent as StrengthGlModelComponent;
    }

    public get punchGlModelComponent(): PunchGlModelComponent {
        if (this.designDetails.designTypeId != designTypes.punch.id) {
            throw new Error('PunchGlModelComponent can only be accessed for designTypeId == punch');
        }

        return this.glModelComponent as PunchGlModelComponent;
    }

    // TODO TEAM: calculate this two arrays once in ngInit and use them when needed
    private strengthCreateDisplayOptionsCheckboxItems(): CheckboxButtonItem<StrengthDisplayOption>[] {
        if (this.strengthDesignDetails.properties.defineOpening) {
            return [
                {
                    id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.Zones),
                    text: this.localizationService.getString('SP.DisplayOptions.ZonesTitle'),
                    value: StrengthDisplayOption.Zones
                },
                {
                    id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.ZonesDimensions),
                    text: this.localizationService.getString('SP.DisplayOptions.ZonesDimensionsTitle'),
                    value: StrengthDisplayOption.ZonesDimensions
                },
                {
                    id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.ZonesNumbering),
                    text: this.localizationService.getString('SP.DisplayOptions.ZonesNumberingTitle'),
                    value: StrengthDisplayOption.ZonesNumbering
                },
                {
                    id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.ConcreteDimensions),
                    text: this.localizationService.getString('SP.DisplayOptions.ConcreteDimensionsTitle'),
                    value: StrengthDisplayOption.ConcreteDimensions
                },
                {
                    id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.TransparentConcrete),
                    text: this.localizationService.getString('SP.DisplayOptions.TransparentConcreteTitle'),
                    value: StrengthDisplayOption.TransparentConcrete
                }
            ].sort((a, b) => a.text.localeCompare(b.text));
        }

        return [
            {
                id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.Zones),
                text: this.localizationService.getString('SP.DisplayOptions.ZonesTitle'),
                value: StrengthDisplayOption.Zones
            },
            {
                id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.ZonesDimensions),
                text: this.localizationService.getString('SP.DisplayOptions.ZonesDimensionsTitle'),
                value: StrengthDisplayOption.ZonesDimensions
            },
            {
                id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.ZonesNumbering),
                text: this.localizationService.getString('SP.DisplayOptions.ZonesNumberingTitle'),
                value: StrengthDisplayOption.ZonesNumbering
            },
            {
                id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.ConcreteDimensions),
                text: this.localizationService.getString('SP.DisplayOptions.ConcreteDimensionsTitle'),
                value: StrengthDisplayOption.ConcreteDimensions
            },
            {
                id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.TransparentConcrete),
                text: this.localizationService.getString('SP.DisplayOptions.TransparentConcreteTitle'),
                value: StrengthDisplayOption.TransparentConcrete
            },
            {
                id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.AnchorSpacingDimensions),
                text: this.localizationService.getString('SP.DisplayOptions.AnchorSpacingDimensionsTitle'),
                value: StrengthDisplayOption.AnchorSpacingDimensions
            },
            {
                id: 'DisplayOption-' + getStrengthDisplayOptionName(StrengthDisplayOption.AnchorEdgeDistanceDimensions),
                text: this.localizationService.getString('SP.DisplayOptions.AnchorEdgeDistanceDimensionsTitle'),
                value: StrengthDisplayOption.AnchorEdgeDistanceDimensions
            }
        ].sort((a, b) => a.text.localeCompare(b.text));
    }

    private punchCreateDisplayOptionsCheckboxItems(): CheckboxButtonItem<PunchDisplayOption>[] {
        const arr = [
            {
                id: 'DisplayOption-' + getPunchDisplayOptionName(PunchDisplayOption.TransparentConcrete),
                text: this.localizationService.getString('SP.DisplayOptions.TransparentConcreteTitle'),
                value: PunchDisplayOption.TransparentConcrete
            },
            {
                id: 'DisplayOption-' + getPunchDisplayOptionName(PunchDisplayOption.ConcreteDimensions),
                text: this.localizationService.getString('SP.DisplayOptions.ConcreteDimensionsTitle'),
                value: PunchDisplayOption.ConcreteDimensions
            },
            {
                id: 'DisplayOption-' + getPunchDisplayOptionName(PunchDisplayOption.CriticalPerimeter),
                text: this.localizationService.getString('SP.DisplayOptions.CriticalPerimeterTitle'),
                value: PunchDisplayOption.CriticalPerimeter
            }
        ];

        if (this.punchDesignDetails.properties.defineStrengtheningElement) {
            arr.push({
                id: 'DisplayOption-' + getPunchDisplayOptionName(PunchDisplayOption.StrengtheningElementSpacingDimensions),
                text: this.localizationService.getString('SP.DisplayOptions.StrengtheningElementSpacingDimensions'),
                value: PunchDisplayOption.StrengtheningElementSpacingDimensions
            });

            arr.push({
                id: 'DisplayOption-' + getPunchDisplayOptionName(PunchDisplayOption.StrengtheningElementEdgeDistanceDimensions),
                text: this.localizationService.getString('SP.DisplayOptions.StrengtheningElementEdgeDistanceDimensions'),
                value: PunchDisplayOption.StrengtheningElementEdgeDistanceDimensions
            });
        }

        if (this.punchDesignDetails.properties.openingsNumberId > 1) {
            arr.push(
            {
                id: 'DisplayOption-' + getPunchDisplayOptionName(PunchDisplayOption.OpeningNumbering),
                text: this.localizationService.getString('SP.DisplayOptions.OpeningNumberingTitle'),
                value: PunchDisplayOption.OpeningNumbering
            },
            {
                id: 'DisplayOption-' + getPunchDisplayOptionName(PunchDisplayOption.OpeningDimensions),
                text: this.localizationService.getString('SP.DisplayOptions.OpeningDimensionsTitle'),
                value: PunchDisplayOption.OpeningDimensions
            });
        }

        return arr.sort((a, b) => a.text.localeCompare(b.text));
    }

    private strengthGetDisplayOptionsCheckboxSelectedValues(): Set<StrengthDisplayOption> | undefined {
        const defineOpening = this.strengthDesignDetails.properties.defineOpening;
        const displayOptions = this.userSettingsService.settings.sp.displayOptions.strength;

        let displayOptionsCheckboxSelectedValues: Set<StrengthDisplayOption>;
        if (defineOpening) {
            displayOptionsCheckboxSelectedValues = new Set([
                displayOptions.zones.value ? StrengthDisplayOption.Zones : undefined!,
                displayOptions.zonesDimensions.value ? StrengthDisplayOption.ZonesDimensions : undefined!,
                displayOptions.zonesNumbering.value ? StrengthDisplayOption.ZonesNumbering : undefined!,
                displayOptions.concreteDimensions.value ? StrengthDisplayOption.ConcreteDimensions : undefined!,
                displayOptions.transparentConcrete.value ? StrengthDisplayOption.TransparentConcrete : undefined!,
            ]);
        }
        else {
            displayOptionsCheckboxSelectedValues = new Set([
                displayOptions.zones.value ? StrengthDisplayOption.Zones : undefined!,
                displayOptions.zonesDimensions.value ? StrengthDisplayOption.ZonesDimensions : undefined!,
                displayOptions.zonesNumbering.value ? StrengthDisplayOption.ZonesNumbering : undefined!,
                displayOptions.concreteDimensions.value ? StrengthDisplayOption.ConcreteDimensions : undefined!,
                displayOptions.transparentConcrete.value ? StrengthDisplayOption.TransparentConcrete : undefined!,
                displayOptions.anchorSpacingDimensions.value ? StrengthDisplayOption.AnchorSpacingDimensions : undefined!,
                displayOptions.anchorEdgeDistanceDimensions.value ? StrengthDisplayOption.AnchorEdgeDistanceDimensions : undefined!
            ]);
        }

        if (this.glModelComponent) {
            void this.strengthGlModelComponent.update({ visibilityModel: { ZonesDimensionsVisible: displayOptions.zonesDimensions.value } });
            void this.strengthGlModelComponent.update({ visibilityModel: { AnchorEdgeDistanceDimensionsVisible: defineOpening ? false : displayOptions.anchorEdgeDistanceDimensions.value } });
            void this.strengthGlModelComponent.update({ visibilityModel: { AnchorSpacingDimensionsVisible: defineOpening ? false : displayOptions.anchorSpacingDimensions.value } });
        }

        displayOptionsCheckboxSelectedValues.delete(undefined!);
        return displayOptionsCheckboxSelectedValues;
    }

    private punchGetDisplayOptionsCheckboxSelectedValues(): Set<PunchDisplayOption> | undefined {
        const displayOptions = this.userSettingsService.settings.sp.displayOptions.punch;

        const displayOptionsCheckboxSelectedValues = new Set([
            displayOptions.transparentConcrete.value ? PunchDisplayOption.TransparentConcrete : undefined!,
            displayOptions.concreteDimensions.value ? PunchDisplayOption.ConcreteDimensions : undefined!,
            displayOptions.criticalPerimeter.value ? PunchDisplayOption.CriticalPerimeter : undefined!,
            displayOptions.strengtheningElementSpacingDimensions.value ? PunchDisplayOption.StrengtheningElementSpacingDimensions : undefined!,
            displayOptions.strengtheningElementEdgeDistanceDimensions.value ? PunchDisplayOption.StrengtheningElementEdgeDistanceDimensions : undefined!,
            displayOptions.openingNumbering.value ? PunchDisplayOption.OpeningNumbering : undefined!,
            displayOptions.openingDimensions.value ? PunchDisplayOption.OpeningDimensions : undefined!
        ]);

        displayOptionsCheckboxSelectedValues.delete(undefined!);
        return displayOptionsCheckboxSelectedValues;
    }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-long-arrow-right-white',
            'sprite-export-design',
            'sprite-duplicate-design',
            'sprite-openfile-d-light',
            'sprite-arrow-left-medium',
            'sprite-arrow-right-medium',
            'sprite-undo',
            'sprite-redo',
            'sprite-search',
            'sprite-center',
            'sprite-view',
            'sprite-info',
            'sprite-warning',
        );

        this.designDetails = this.userService.design.designDetails;
        this.trackingDetails = this.userService.design.trackingDetails;
        this.design = this.userService.design;
        this.pushDesignDetailsHistory(this.designDetails);

        this.menuService.setMenu({
            propertyChange: this.propertyChange
        });
        this.TrackDataOnTabClose();

        this.updateDisplayedOptionsCheckBox();

        this.sortableMenu3DRightOptions = {
            handle: '.drag-handle-static',
            store: {
                get: () => {
                    return [];
                },
                set: (sortable) => {
                    this.regionOrderService.update(sortable.toArray(), MenuType.Menu3DRight)
                        .catch((error: unknown) => console.error(error));
                }
            },
            onSort: () => undefined
        };

        this.updateLoads();

        this.setNotificationComponentInputs();

        // show design changed popup
        const convertChanges = this.design.convertChanges;
        if (convertChanges != null) {
            this.modalService.showConvertWarningDialog(convertChanges);

            // clear convert changes so warning popup is shown only once
            this.design.convertChanges = undefined;
        }

        this.initGlModel();
    }

    public ngAfterViewInit(): void {
        this.initMenu3d();
        this.initMenuTabSelection();
        this.initRightSide();
        this.startDesignTour();
    }

    public ngOnDestroy(): void {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        window.removeEventListener('beforeunload', this.onBeforeUnloadEvent, false);

        // no need to await
        this.designService.closeDesignOrDesignTemplate(this.designDetails, this.trackingDetails)
            .catch((error: unknown) => console.error(error));

        // TODO FILIP: do we need this?
        // Fix for the issue that happens when you close the design before the introduction loads
        if (this.openedVirtualTour != null) {
            setTimeout(() => {
                document.querySelectorAll('.introjs-helperLayer, .introjs-tooltipReferenceLayer, .introjs-tooltip, .introjs-overlay, .introjs-disableInteraction')
                    .forEach(element => element.remove());
            });
        }
    }

    public get updatePending() {
        return this.userService.design?.pendingCalculation ?? false;
    }

    public get selectedLanguage() {
        return this.localizationService.selectedLanguage;
    }

    public get projectName() {
        return this.designDetails.projectName;
    }

    public get title() {
        const titleParts = [
            this.getBaseTitle(),
            this.localizationService.getString(this.designDetails.region.nameKey),
            this.getDesignStandardName(),
            this.getDesignMethodName(),
            this.getApprovalNumber()
        ];

        return titleParts.join(', ');
    }

    public get undoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.Undo');
    }

    public get redoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.Redo');
    }

    public undo() {
        if (!this.canUndo()) {
            return;
        }

        // tracking counters
        this.trackingDetails.counters.designUndo++;

        this.designDetailsHistoryIndex--;
        this.designDetails = this.designDetailsHistory[this.designDetailsHistoryIndex];
        this.designService.updatePeDesignObject(this.userService.design, this.designDetails);

        this.updateAndDetectChanges();

        this.documentServiceUpdateDesign();
    }

    public redo() {
        if (!this.canRedo()) {
            return;
        }

        // tracking counters
        this.trackingDetails.counters.designRedo++;

        this.designDetailsHistoryIndex++;
        this.designDetails = this.designDetailsHistory[this.designDetailsHistoryIndex];
        this.designService.updatePeDesignObject(this.userService.design, this.designDetails);

        this.updateAndDetectChanges();

        this.documentServiceUpdateDesign();
    }

    private documentServiceUpdateDesign() {
        // no await needed
        this.designService
            .documentServiceUpdateDesignOrDesignTemplate({
                designId: this.designDetails.designId,
                designName: this.designDetails.designName,
                projectId: this.designDetails.projectId,

                templateId: this.designDetails.templateId,
                templateName: this.designDetails.templateName,
                templateProjectId: this.designDetails.templateProjectId,

                designStandardId: this.designDetails.properties.designStandardId,
                designTypeId: this.designDetails.designTypeId,
                projectDesign: this.designDetails.projectDesign,
                regionId: this.designDetails.regionId,

                immediateRequest: false
            })
            .catch((error: unknown) => console.error(error));

        // no await needed
        this.documentServiceUpdateDesignImage()
            .catch((error: unknown) => console.error(error));

        // no await needed
        this.designService
            .trackOnDesignOrTemplateChange({
                designDetails: this.designDetails,
                trackingDetails: this.trackingDetails,

                immediateRequest: false
            })
            .catch((error: unknown) => console.error(error));
    }

    private trackingServiceChange() {
        // no await needed
        this.designService
            .trackOnDesignOrTemplateChange({
                designDetails: this.designDetails,
                trackingDetails: this.trackingDetails,

                immediateRequest: false
            })
            .catch((error: unknown) => console.error(error));
    }

    public createScreenshot3D(screenshotSettings: ScreenshotSettings) {
        return this.glModelComponent.createDesignScreenshot(screenshotSettings);
    }

    private async documentServiceUpdateDesignImage(immediateRequest = false) {
        // TODO FILIP: createDesignScreenshot should be sync
        const screenshot = await this.glModelComponent.createDesignScreenshot({
            isThumbnail: true,
            imgHeight: 145,
            imgWidth: 145,
            zoomed: false,
            preview: false,
            loadsVisibilityInfo: undefined as unknown as LoadsVisibilityInfo
        });

        await this.designService.updateDesignImageOrTemplateDesignImage({
            templateId: this.designDetails.templateId,
            designId: this.designDetails.designId,
            base64Image: screenshot,

            immediateRequest
        });
    }

    public canUndo() {
        return this.designDetailsHistoryIndex > 0 &&
            !this.featuresVisibilityInfoService.isDisabled(Feature.Design_UndoRedo, this.designDetails.regionId);
    }

    public canRedo() {
        return this.designDetailsHistoryIndex < this.designDetailsHistory.length - 1 &&
            !this.featuresVisibilityInfoService.isDisabled(Feature.Design_UndoRedo, this.designDetails.regionId);
    }

    public zoomPercentageChange(value: number) {
        // The value is changed inside the same cycle so change detection
        // needs to be run again before the new change
        this.modelViewZoom = value;
        this.changeDetector.detectChanges();

        this.glModelComponent.cameraZoom(value);
    }

    public zoomToFit() {
        // extraInfo not used
        const extraInfo = undefined as unknown as ScreenshotSettings;

        this.glModelComponent.zoomToFit(extraInfo);
    }

    public resetCamera() {
        this.glModelComponent.resetCamera();
    }

    public displayOptionsCheckboxItemToggle(displayOption: number): Promise<void> {
        return designTypeSwitch(this.designDetails.designTypeId,
            () => this.strengthDisplayOptionsCheckboxItemToggle(displayOption),
            () => this.punchDisplayOptionsCheckboxItemToggle(displayOption),
        );
    }

    public async strengthDisplayOptionsCheckboxItemToggle(displayOption: StrengthDisplayOption) {
        const displayOptions = this.userSettingsService.settings.sp.displayOptions.strength;
        const isChecked = this.displayOptionsCheckbox.selectedValues!.has(displayOption);

        switch (displayOption) {
            case StrengthDisplayOption.Zones:
                displayOptions.zones.value = isChecked;
                await this.strengthGlModelComponent.update({ visibilityModel: { ZonesVisible: isChecked } });

                break;
            case StrengthDisplayOption.ZonesDimensions:
                displayOptions.zonesDimensions.value = isChecked;
                await this.strengthGlModelComponent.update({ visibilityModel: { ZonesDimensionsVisible: isChecked } });

                break;
            case StrengthDisplayOption.ZonesNumbering:
                displayOptions.zonesNumbering.value = isChecked;
                await this.strengthGlModelComponent.update({ visibilityModel: { ZonesNumberingVisible: isChecked } });

                break;
            case StrengthDisplayOption.ConcreteDimensions:
                displayOptions.concreteDimensions.value = isChecked;
                await this.strengthGlModelComponent.update({ visibilityModel: { ConcreteDimensionsVisible: isChecked } });

                break;
            case StrengthDisplayOption.TransparentConcrete:
                displayOptions.transparentConcrete.value = isChecked;
                await this.strengthGlModelComponent.update({ visibilityModel: { TransparentConcrete: isChecked } });

                break;
            case StrengthDisplayOption.AnchorSpacingDimensions:
                displayOptions.anchorSpacingDimensions.value = isChecked;
                await this.strengthGlModelComponent.update({ visibilityModel: { AnchorSpacingDimensionsVisible: isChecked } });

                break;
            case StrengthDisplayOption.AnchorEdgeDistanceDimensions:
                displayOptions.anchorEdgeDistanceDimensions.value = isChecked;
                await this.strengthGlModelComponent.update({ visibilityModel: { AnchorEdgeDistanceDimensionsVisible: isChecked } });

                break;
            default:
                throw new Error('Unknown StrengthDisplayOption');
        }

        this.userSettingsService.debounceSave();
    }

    public async punchDisplayOptionsCheckboxItemToggle(displayOption: PunchDisplayOption) {
        const displayOptions = this.userSettingsService.settings.sp.displayOptions.punch;
        const isChecked = this.displayOptionsCheckbox.selectedValues!.has(displayOption);

        switch (displayOption) {
            case PunchDisplayOption.TransparentConcrete:
                displayOptions.transparentConcrete.value = isChecked;
                await this.punchGlModelComponent.update({ visibilityModel: { TransparentConcrete: isChecked } });
                break;
            case PunchDisplayOption.ConcreteDimensions:
                displayOptions.concreteDimensions.value = isChecked;
                await this.punchGlModelComponent.update({ visibilityModel: { ConcreteDimensionsVisible: isChecked } });
                break;
            case PunchDisplayOption.CriticalPerimeter:
                displayOptions.criticalPerimeter.value = isChecked;
                await this.punchGlModelComponent.update({ visibilityModel: { CriticalPerimeter: isChecked } });
                break;
            case PunchDisplayOption.StrengtheningElementSpacingDimensions:
                displayOptions.strengtheningElementSpacingDimensions.value = isChecked;
                await this.punchGlModelComponent.update({ visibilityModel: { StrengtheningElementSpacingDimensions: isChecked } });
                break;
            case PunchDisplayOption.StrengtheningElementEdgeDistanceDimensions:
                displayOptions.strengtheningElementEdgeDistanceDimensions.value = isChecked;
                await this.punchGlModelComponent.update({ visibilityModel: { StrengtheningElementEdgeDistanceDimensions: isChecked } });
                break;
            case PunchDisplayOption.OpeningNumbering:
                displayOptions.openingNumbering.value = isChecked;
                await this.punchGlModelComponent.update({ visibilityModel: { OpeningNumbering: isChecked } });
                break;
            case PunchDisplayOption.OpeningDimensions:
                displayOptions.openingDimensions.value = isChecked;
                await this.punchGlModelComponent.update({ visibilityModel: { OpeningDimensions: isChecked } });
                break;
            default:
                throw new Error('Unknown PunchDisplayOption');
        }

        this.userSettingsService.debounceSave();
    }

    public toggleLeftMenu() {
        this.hideLeftMenu = !this.hideLeftMenu;

        this.resize3dAfterUI();
    }

    public toggleRightMenu() {
        this.hideRightMenu = !this.hideRightMenu;

        this.resize3dAfterUI();
    }

    public TrackDataOnTabClose() {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        window.addEventListener('beforeunload', this.onBeforeUnloadEvent, false);
    }

    public getDesignInfoForDesignType(): IDesignInfo {
        return this.applicationProviderService.getDesignInfo()
            .find(x => x.designTypeId == this.designDetails.designTypeId)!;
    }

    public openDesignSettings() {
        const designInfo = this.getDesignInfoForDesignType();
        this.modalService.openAddEditDesignFromModule({
            // TODO FILIP: check what we need from this design properties
            design: {
                id: this.designDetails.designId,
                name: this.designDetails.isTemplate ? this.designDetails.templateName : this.designDetails.designName,
                projectId: this.designDetails.projectId,
                projectName: this.designDetails.projectName,
                region: this.designDetails.commonRegion,
                designType: this.designDetails.designTypeId,
                displayDesignType: this.designDetails.isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
                designTemplateDocumentId: this.designDetails.templateId,
                design: this.userService.design
            },
            addEditType: AddEditType.edit,
            afterOpenInstructions: undefined,
            selectedModuleDesignInfo: designInfo,
            onDesignEdited: (_designDetails) => {
                const designDetails = _designDetails as DesignDetails;

                this.designDetailsChange(designDetails);
            }
        });
    }

    public startTour() {
        this.modalService.openVirtualTourPopup(this.selectTab);
    }

    public openSaveAsTemplate() {
        this.modalService.openSaveAsTemplate({
            designTemplateDocument: this.getDesignTemplateDocument(),
            thumbnailId: this.designDetails.designId,
            onTemplateSaved: this.onTemplateSaved.bind(this)
        });
    }

    public get mainMenuComponent() {
        return this.mainMenuComponentElementRef?.nativeElement;
    }

    public resize3d() {
        this.glModelComponent.resizeNextFrame();
    }

    public get isCalculationValid() {
        return this.designService.isCalculationValid(this.designDetails.calculateResult);
    }

    public openGeneralNotes() {
        // TODO: BUDQBP-23561
        // TODO FILIP: fix

        const copyText = this.localizationService.getString('Agito.Hilti.Profis3.GeneralNotes.CopyText');
        const text = this.localizationService.getString('Agito.Hilti.Profis3.GeneralNotes.DisplayText');

        this.modalService.openGeneralNotes(text, copyText);
    }

    public menuOpened() {
        this.trackingDetails.counters.headerMenuOpened++;
        this.trackingServiceChange();
    }

    public hiltiDataPrivacyUrlOpened() {
        this.trackingDetails.counters.headerOnlineTechnicalInformation++;
        this.trackingServiceChange();
    }

    public regionLinkOpened() {
        this.trackingDetails.counters.headerOnlineTechnicalInformation++;
        this.trackingServiceChange();
    }

    public get selectedMenu(): IMenu {
        return this.mainMenuComponent?.getSelectedMenu() ?? {} as IMenu;
    }

    public get hasExtendedWidth() {
        if (this.selectedMenu?.tabs == null) {
            return false;
        }

        const selectedTab = this.selectedMenu?.tabs[this.selectedMenu.selectedTab];
        const canExtend = selectedTab?.width == NavigationTabWidth.Extended;
        return canExtend && !this.hideLeftMenu;
    }

    public startDesignTour() {
        if (this.userService?.design == null) {
            return;
        }

        // Get all tours that are available for design and not yet seen.
        const availableTours = this.tourService.getVirtualTours()
            .filter(x => x.order != null)
            .filter(x => SafeFunctionInvokerHelper.safeInvoke(x.isAvailable, false))
            .filter(x => !SafeFunctionInvokerHelper.safeInvoke(x.alreadySeen, false))
            .sort((a, b) => ((a.order ?? 0) - (b.order ?? 0)));

        // First tour shall start immediately, others should start after previous one is completed.
        const startNextTour = () => {
            const tour = availableTours.shift();
            if (tour == null) {
                return;
            }

            this.openedVirtualTour = tour.openTour(this.selectTab.bind(this));

            // oncomplete is triggered if Got It button is clicked on last step, onexit is triggered if dismiss button is clicked on any other step
            const exitFn = () => {
                this.openedVirtualTour = undefined;

                // TODO TEAM: is it ok to not await?
                tour.markAsSeen()
                    .catch((error: unknown) => console.error(error));

                startNextTour();
            };
            this.openedVirtualTour.oncomplete(exitFn.bind(this));
            this.openedVirtualTour.onexit(exitFn.bind(this));
        };

        startNextTour();
    }

    private pushDesignDetailsHistory(designDetails: DesignDetails) {
        this.designDetailsHistoryIndex++;
        this.designDetailsHistory.splice(this.designDetailsHistoryIndex);
        this.designDetailsHistory.push(designDetails);

        if (this.designDetailsHistoryIndex > 50) {
            this.designDetailsHistoryIndex--;
            this.designDetailsHistory.splice(0, 1);
        }
    }

    private onOpenApproval(designDetails: DesignDetails) {
        this.trackingDetails.counters.approvalViewed++; // tracking counters
        this.approvalsService.openApprovalLink(designDetails.properties.approval);
        this.trackingServiceChange();
    }

    private strengthMenu3dCommands: Record<string, (navigationControl?: BaseControl) => void> = {
        [commandFromService(Command.OpenApproval)]: () => this.onOpenApproval(this.strengthDesignDetails),

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        [commandFromService(Command.OpenDrillingAidPopup)]: () => this.modalService.openWebComponentModal('sp-info-dialog-drilling-aid'),

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        [commandFromService(Command.OpenDepthOfRecessPopup)]: () => this.modalService.openWebComponentModal('sp-info-dialog-depth-of-recess'),

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        [commandFromService(Command.OpenCrossSectionalAreaPopup)]: () => this.modalService.openWebComponentModal('sp-info-dialog-as'),

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        [commandFromService(Command.OpenEffectiveHeightPopup)]: () => this.modalService.openWebComponentModal('sp-info-dialog-effective-height'),

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        [commandFromService(Command.OpenCoverPopup)]: () => this.modalService.openWebComponentModal('sp-info-dialog-cover', undefined, { strengthDesignDetails: this.strengthDesignDetails }),

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        [commandFromService(Command.OpenInstallationDirectionPopup)]: () => this.modalService.openWebComponentModal('sp-info-dialog-installation-direction'),

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        [commandFromService(Command.OpenTransverseEccentricityPopup)]: () => this.modalService.openWebComponentModal('sp-info-dialog-transverse-eccentricity'),

        // eslint-disable-next-line @typescript-eslint/no-deprecated
        [commandFromService(Command.OpenReinforcementEffectivenessPopup)]: () => this.modalService.openWebComponentModal('sp-info-dialog-reinforcement-effectiveness'),

        [commandFromService(Command.OpenFastenerSelectionPopup)]: () => this.openFastenerSelectionPopup(this.strengthDesignDetails),
    };

    private readonly punchMenu3dCommands: Record<string, (navigationControl?: BaseControl) => void> = {
        [commandFromService(Command.OpenFastenerSelectionPopup)]: () => this.openFastenerSelectionPopup(this.punchDesignDetails),
        [commandFromService(Command.OpenApproval)]: () => this.onOpenApproval(this.punchDesignDetails),
    };

    private openFastenerSelectionPopup(designDetails: DesignDetails): void {
        this.modalService.openComponentModal(
            ProductSelectionComponent,
            { size: 'lg' },
            [{
                provide: ProductSelectionInput, useValue: this.getProductSelectionInput(designDetails)
            }],
        );
    };

    private getProductSelectionInput(designDetails: DesignDetails): ProductSelectionInput {
        const products = this.dataService.fastenerFamilyGroups;
        const sortedProducts = [...products]
            // sort by regional sort no
            .sort((a, b) => {
                const aSortNumber = a.regionalSortNumbers?.[designDetails.regionId] ?? Number.MAX_SAFE_INTEGER;
                const bSortNumber = b.regionalSortNumbers?.[designDetails.regionId] ?? Number.MAX_SAFE_INTEGER;
                return aSortNumber - bSortNumber;
            })
            .map((x) => {
                const sprite = 'sprite-product-ui-' + x.image as Sprite;
                return {
                    id: x.id,
                    name: x.regionalNames ? x.regionalNames[designDetails.regionId] : '',
                    image: sprite,
                    imageStyle: {
                        elementStyle: getSpriteAsIconStyle(sprite)
                    }
                };
            });

        return {
            selectedProductId: designDetails.properties.fastenerFamilyGroupId,
            products: sortedProducts,
            productSelected: async (productId) => {
                await this.propertyChange([
                    { propertyId: 'fastenerFamilyGroupId', propertyValue: productId }
                ]);
            }
        };
    }

    private getMenu3dCommands(): Record<string, (navigationControl?: BaseControl) => void> {
        return designTypeSwitch(this.designDetails.designTypeId,
            () => this.strengthMenu3dCommands,
            () => this.punchMenu3dCommands,
        );
    }

    private initMenu3d() {
        this.mainMenuComponent.initMenu3d(
            this.userService.design,
            this.tabSelected,
            this.getMenu3dCommands()
        );
    }

    private initMenuTabSelection() {
        if (this.mainMenuComponent.isTabEmptyOrHidden?.('tab-favorites')) {
            this.selectTabById('application-tab');
        }
    }

    private tabSelected() {
        this.hideLeftMenu = false;

        this.resize3dAfterUI();
    }

    private initGlModel(): void {
        const model = this.createDefaultGlModel();

        this.glModel = {
            continuousRender: environment.debugGlModel,
            model: model as Model,
            onZoom: (zoom) => {
                this.modelViewZoom = Math.round(100 - zoom);
            },
            onFontsLoaded: () => {
                (async () => {
                    this.updateGlModelFromProperties();

                    // somehow if we don't call update before renderWhenReady some parts of 3D won't be ready
                    await this.glModelComponent.update({ hidden: true });
                    await this.glModelComponent.renderWhenReady();
                    await this.glModelComponent.update({ hidden: false });

                    await this.documentServiceUpdateDesignImage(true);
                })().catch((error: unknown) => console.error(error));
            },
            onSelectTab: (tab) => {
                this.selectTab(tab);
            },
            propertyChange: this.propertyChange
        };
    }

    private createDefaultGlModel(): PartialModel {
        return designTypeSwitch(this.designDetails.designTypeId,
            () => this.strengthCreateDefaultGlModel(),
            () => this.punchCreateDefaultGlModel(),
        );
    }

    private strengthCreateDefaultGlModel(): StrengthPartialModel {
        const displayOptions = this.userSettingsService.settings.sp.displayOptions.strength;

        return {
            baseMaterial: {},
            zones: {},
            visibilityModel: {
                ZonesVisible: displayOptions.zones.value,
                ZonesDimensionsVisible: displayOptions.zonesDimensions.value,
                ZonesNumberingVisible: displayOptions.zonesNumbering.value,
                ConcreteDimensionsVisible: displayOptions.concreteDimensions.value,
                TransparentConcrete: displayOptions.transparentConcrete.value,
                AnchorSpacingDimensionsVisible: displayOptions.anchorSpacingDimensions.value,
                AnchorEdgeDistanceDimensionsVisible: displayOptions.anchorEdgeDistanceDimensions.value
            },
            postInstalledElement: {},
            opening: {}
        };
    }

    private punchCreateDefaultGlModel(): PunchPartialModel {
        const displayOptions = this.userSettingsService.settings.sp.displayOptions.punch;

        return {
            baseMaterial: {},
            punchPostInstalledElement: {},
            visibilityModel: {
                TransparentConcrete: displayOptions.transparentConcrete.value,
                ConcreteDimensionsVisible: displayOptions.concreteDimensions.value,
                CriticalPerimeter: displayOptions.criticalPerimeter.value,
                StrengtheningElementSpacingDimensions: displayOptions.strengtheningElementSpacingDimensions.value,
                StrengtheningElementEdgeDistanceDimensions: displayOptions.strengtheningElementEdgeDistanceDimensions.value,
                OpeningNumbering: displayOptions.openingNumbering.value,
                OpeningDimensions: displayOptions.openingDimensions.value
            }
        };
    }

    private updateGlModelFromProperties() {
        this.glModelComponent.propertyValueChanged(null!, null!, Update.ServerAndClient);
    }

    public async propertyChange(propertyChanges: PropertyIdValue[]): Promise<void> {
        if (propertyChanges == null || propertyChanges.length == 0) {
            return;
        }

        try {
            this.userService.design.pendingCalculation = this.spApiService.isLongRunning;
            this.refreshHeader();

            const updateDesignResult = await this.designService.updateDesignOrDesignTemplate({
                designId: this.designDetails.designId,
                designName: this.designDetails.designName,
                projectId: this.designDetails.projectId,

                templateId: this.designDetails.templateId,
                templateName: this.designDetails.templateName,
                templateProjectId: this.designDetails.templateProjectId,

                projectDesign: this.designDetails.projectDesign,
                properties: propertyChanges,

                trackingDetails: this.trackingDetails,

                immediateRequest: false
            });

            // skip if we have pending updates
            if (updateDesignResult?.resetAction) {
                this.resetAction();
            }
            else if (updateDesignResult?.designDetails != null) {
                this.designDetailsChange(updateDesignResult.designDetails);
            }
        }
        catch (error) {
            console.error(error);

            // if we don't have a successful call reload UI with the same values (revert of the change)

            // menu has this strange async update and error popup will steal focus which triggers menu update
            // so we wait for this async stuff to finish before we revert the changes in the menu
            await new Promise<void>((resolve, reject) => {
                setTimeout(() => {
                    try {
                        this.designService.updatePeDesignObject(this.userService.design, this.designDetails);

                        this.userService.design.pendingCalculation = false;
                        this.refreshHeader();

                        resolve();
                    }
                    catch (error) {
                        reject(error as Error);
                    }
                });
            });

            throw error;
        }
    }

    private resetAction() {
        this.userService.design.pendingCalculation = false;
        this.designService.updatePeDesignObject(this.userService.design, this.designDetails);
    }

    private designDetailsChange(designDetails: DesignDetails) {
        this.userService.design.pendingCalculation = false;
        this.refreshHeader();

        this.designDetails = designDetails;
        this.pushDesignDetailsHistory(this.designDetails);
        this.designService.updatePeDesignObject(this.userService.design, designDetails);

        this.design = this.userService.design;

        this.updateAndDetectChanges();

        // no await needed
        this.documentServiceUpdateDesignImage()
            .catch((error: unknown) => console.error(error));
    }

    private updateDisplayedOptionsCheckBox() {
        designTypeSwitch(this.designDetails.designTypeId,
            () => this.strengthUpdateDisplayedOptionsCheckBox(),
            () => this.punchUpdateDisplayedOptionsCheckBox()
        );
    }

    private strengthUpdateDisplayedOptionsCheckBox() {
        this.displayOptionsCheckbox = {
            items: this.strengthCreateDisplayOptionsCheckboxItems(),
            selectedValues: this.strengthGetDisplayOptionsCheckboxSelectedValues()
        };
    }

    private punchUpdateDisplayedOptionsCheckBox() {
        this.displayOptionsCheckbox = {
            items: this.punchCreateDisplayOptionsCheckboxItems(),
            selectedValues: this.punchGetDisplayOptionsCheckboxSelectedValues()
        };
    }

    private refreshHeader() {
        // TODO FILIP: remove this hack once common header is fixed
        // trigger common header refresh
        this.openGeneralNotes = () => this.openGeneralNotes();
    }

    public loadsVisible() {
        return true;
    }

    private initRightSide() {
        this.rightSideLoaded = true;
    }

    private getDesignTemplateDocument(): IDesignTemplateDocument {
        return {
            designTypeId: this.designDetails.designTypeId,
            // TODO FILIP: why does pe-ui need design standard?
            designStandardId: this.designDetails.properties.designStandardId,
            regionId: this.designDetails.regionId,
            anchorName: '',
            approvalNumber: '',
            projectDesign: JSON.stringify(this.designDetails.projectDesign)
        };
    }

    private async onTemplateSaved() {
        await this.routingService.navigateToUrl(UrlPath.projectAndDesign);
    }

    private async onBeforeUnloadEvent() {
        if (!this.userLogout) {
            await this.trackingService.trackOnCloseBrowserUnloadEvent(this.designDetails, this.trackingDetails, this.designDetails.isTemplate);
        }
    }

    public async beforeLogout() {
        this.userLogout = true; // we set this to true so we ignore beforeunload event
        await this.processDesignClose();
    }

    public async processDesignClose(): Promise<void> {
        const licensePromise = this.userService.releaseAllFloatingLicenses(true);
        const trackingPromise = this.designDetails.isTemplate
            ? this.trackingService.trackOnTemplateClose(this.designDetails, this.trackingDetails)
            : this.trackingService.trackOnDesignClose(this.designDetails, this.trackingDetails);
        await Promise.all([trackingPromise, licensePromise]);
    }

    private selectTab(tab: string) {
        this.selectTabById(`tab-${tab}`);

        this.hideLeftMenu = false;
        this.resize3dAfterUI();
    }

    private selectTabById(tab: string) {
        this.mainMenuComponent?.selectTab(tab);
    }

    private resize3dAfterUI() {
        // the UI might update later so we resize it twice
        this.resize3d();

        setTimeout(() => {
            this.resize3d();
        });
    }

    public updateLoads() {
        if (this.designDetails.designTypeId == designTypes.punch.id) {
            this.punchUpdateLoads();
        }
        else {
            this.strengthUpdateLoads();
        }
    }

    private punchUpdateLoads() {
        this.punchLoadCombinations = [];

        this.punchDesignDetails.properties.loadCombinations.forEach(val =>
            this.punchLoadCombinations.push({ ...val })
        );

        this.punchLoadCombinations = this.punchDesignDetails.properties.loadCombinations
            .map((x, i): PunchLoadCombination => ({
                loadTypeId: x.loadTypeId,
                loadCombinationName: x.loadCombinationName ?? this.defaultLoadCombinationName(i + 1),
                ved: x.ved,
                medX: x.medX,
                medY: x.medY
            }));
    }

    private strengthUpdateLoads() {
        this.loadCombinations = [];

        this.strengthDesignDetails.properties.loadCombinations.forEach(val =>
            this.loadCombinations.push({ ...val })
        );

        this.loadCombinations = this.strengthDesignDetails.properties.loadCombinations
            .map((x, i): LoadCombination => ({
                loadTypeId: x.loadTypeId,
                loadCombinationName: x.loadCombinationName ?? this.defaultLoadCombinationName(i + 1),
                zoneLoads: x.zoneLoads.map((y): ZoneLoad => ({
                    load: y.load,
                    zoneNumber: y.zoneNumber
                }))
            }));
    }

    private defaultLoadCombinationName(index: number) {
        return this.localizationService.getString('SP.Loads.CombinationDefaultName') + ' ' + index;
    }

    public async onLoadCombinationsChanged<T>(loadCombinations: T[]) {
        await this.propertyChange([{
            propertyId: 'loadCombinations',
            propertyValue: loadCombinations
        }]);
    }

    private get hasScopeChecks() {
        return this.scopeChecks.length > 0;
    }

    private get scopeChecks() {
        return this.designDetails.calculateResult?.scopeCheckResults.failedScopeChecks ?? [];
    }

    private setNotificationComponentInputs() {
        const scopeChecksOrInvalidCalculation = this.hasScopeChecks || this.designDetails.calculateResult == null;

        this.notificationComponentInputs = {
            isVisible: () => {
                return scopeChecksOrInvalidCalculation;
            },
            isInfoMessageVisible: () => {
                return true;
            },
            notifications: [],
            scopeChecks: this.notificationScopeChecks
        } as INotificationsComponentInput;
    }

    private updateAndDetectChanges() {
        this.updateLoads();
        this.setNotificationComponentInputs();

        // sync designDetails with gl-model before we update it
        this.changeDetector.detectChanges();
        this.updateGlModelFromProperties();

        this.updateDisplayedOptionsCheckBox();
    }

    public get notificationScopeChecks() {
        if (this.designDetails.calculateResult == null) {
            return this.getCalculationErrorScopeCheck();
        }

        return this.designDetails.calculateResult?.scopeCheckResults.failedScopeChecks.map(sc => ({
            type: this.getNotificationType(sc.severity),
            message: this.translationFormatService.getScopeCheckHtml(sc.message),
            hasSupportButton: sc.severity == ScopeCheckSeverity.Critical
                ? () => true
                : () => false,
            supportButtonClick: sc.severity == ScopeCheckSeverity.Critical
                ? () => this.scopeCheckSupportButtonClick()
                : () => undefined
        } as INotificationScopeCheck));
    }

    public getNotificationType(severity: ScopeCheckSeverity) {
        switch (severity) {
            case ScopeCheckSeverity.Info:
                return NotificationType.info;
            case ScopeCheckSeverity.Error:
                return NotificationType.alert;
            case ScopeCheckSeverity.Critical:
                return NotificationType.alert;
        }
    }

    public sortMenu3DRight(sortable: Sortable) {
        sortable.sort(this.favoritesService.menu3DRightOrder.map(String));
    }

    public getCalculationErrorScopeCheck() {
        return [{
            type: NotificationType.alert,
            message: this.localizationService.getString('SP.ScopeCheck.ScErrorUnhandled'),
            hasSupportButton: () => true,
            supportButtonClick: () => this.scopeCheckSupportButtonClick()
        }];
    }

    public scopeCheckSupportButtonClick() {
        if (this.design?.projectDesign == null) {
            throw new Error('Project design is not set.');
        }

        this.modalService.openSupport(undefined, this.design.projectDesign as object);
    }

    private getBaseTitle(): string {
        let baseTitle = this.designDetails.isTemplate
            ? this.designDetails.templateName!
            : this.designDetails.designName!;

        if (!this.designDetails.isTemplate) {
            baseTitle += ` (${this.designDetails.projectName})`;
        }
        else {
            baseTitle += ` (${this.localizationService.getString('Agito.Hilti.Profis3.Main.TemplateProjectName')})`;
        }

        if (!baseTitle) {
            throw new Error('Template name is missing');
        }

        return baseTitle;
    }

    private getDesignStandardName(): string {
        const designStandardNameKey = this.dataService.designStandardsById[this.designDetails.properties.designStandardId]?.nameKey;
        return designStandardNameKey ? this.localizationService.getString(designStandardNameKey) : 'Unknown';
    }

    private getDesignMethodName(): string {
        const designMethodName = this.designDetails.properties.designMethodName;
        const approvalName = this.localizationService.getString(this.dataService.postInstalledReinforcementDesignsById[this.designDetails.properties.postInstalledReinforcementDesignId].nameKey);

        return (this.designService.isCalculationValid(this.designDetails.calculateResult) && designMethodName && approvalName)
            ? `${designMethodName} + ${approvalName}`
            : this.localizationService.getString('SP.DesignMethod.None');
    }

    private getApprovalNumber(): string {
        return this.approvalsService.getApprovalNumber(this.designDetails.properties.approval);
    }
}
