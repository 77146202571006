import { Vector2, Vector3 } from '@babylonjs/core/Maths/math';
import { CompressionMemberId, PunchModel } from '../../punch-gl-model';
import { washerFillHolesHeight } from './post-installed-element';
import { PlaneDirection } from './measurements/punch-measurements-base-material';
import { Direction2d } from '../../../services/design.service';

export const baseMaterialAlphaIndex = 2;

export function getPunchSpanOffset(model: PunchModel): Vector3 {
    return new Vector3(
        getPunchSpanOffsetX(model),
        getPunchSpanOffsetY(model),
        getPunchSpanOffsetZ(model)
    );
}
export function getPunchSpanOffsetX(model: PunchModel) {
    const spanX = model.baseMaterial.spanNegX + model.baseMaterial.spanPosX;
    return -spanX / 2 + model.baseMaterial.spanNegX;
}
export function getPunchSpanOffsetY(model: PunchModel) {
    const spanY = model.baseMaterial.spanNegY + model.baseMaterial.spanPosY;
    return -spanY / 2 + model.baseMaterial.spanNegY;
}
export function getPunchSpanOffsetZ(model: PunchModel) {
    return model.baseMaterial.thickness / 2;
}

export function getPunchWasherDiameter(model: PunchModel): number {
    return model.punchPostInstalledElement.anchorDiameter * 3;
}
export function getWasherZPosition(model: PunchModel): number {
    return model.baseMaterial.thickness / 2 + washerFillHolesHeight / 2 - model.punchPostInstalledElement.depthOfRecess;
}
export function getRecessHoleZPosition(model: PunchModel): number {
    return model.baseMaterial.thickness / 2 - model.punchPostInstalledElement.depthOfRecess / 2;
}

export function translateKernelPointTo3dPoint(model: PunchModel, point: Vector2, positionZ: number): Vector3 {
    const offset = getPunchSpanOffset(model);
    return new Vector3(point.x - offset.x, point.y - offset.y, positionZ);
}

export function getClosestEdgePoint(model: PunchModel, firstPerimeter: Vector2) {
    const isRectangular = model.baseMaterial.compressionMemberId == CompressionMemberId.Rectangular;
    const width = isRectangular ? model.baseMaterial.punchWidth / 2 : model.baseMaterial.punchDiameter / 2;
    const length = isRectangular ? model.baseMaterial.punchLength / 2 : model.baseMaterial.punchDiameter / 2;
    let closestEdgePoint;
    let direction;

    if (firstPerimeter.x >= -length && firstPerimeter.y < -width) {
        closestEdgePoint = isRectangular ? new Vector2(firstPerimeter.x, -width) : new Vector2(0, -width);
        direction = PlaneDirection.Bottom;
    }
    else if (firstPerimeter.x > length && firstPerimeter.y >= -width) {
        closestEdgePoint = isRectangular ? new Vector2(length, firstPerimeter.y) : new Vector2(length, 0);
        direction = PlaneDirection.Right;
    }
    else if (firstPerimeter.x <= length && firstPerimeter.y > width) {
        closestEdgePoint = isRectangular ? new Vector2(firstPerimeter.x, width) : new Vector2(0, width);
        direction = PlaneDirection.Top;
    }
    else {
        closestEdgePoint = isRectangular ? new Vector2(-length, firstPerimeter.y) : new Vector2(-length, 0);
        direction = PlaneDirection.Left;
    }

    return { closestEdgePoint, direction };
}

export function getFirstAndSecondPerimeterElements(model: PunchModel) {
    if (model.punchPostInstalledElement.perimeters.length < 2) {
        return {
            firstPerimeter: undefined,
            secondPerimeter: undefined
        };
    }

    const firstPerimeterPoints = model.punchPostInstalledElement.perimeters[0].points.map(point =>
        translateKernelPointTo3dPoint(model, point, 0)
        );

    const secondPerimeterPoints = model.punchPostInstalledElement.perimeters[1].points.map(point =>
        translateKernelPointTo3dPoint(model, point, 0)
        );

    const match = getMatch(model, firstPerimeterPoints, secondPerimeterPoints);

    if (match == undefined) {
        return {
            firstPerimeter: undefined,
            secondPerimeter: undefined
        };
    }

    return {
        firstPerimeter: new Vector2(match.firstPerimeterPoint.x, match.firstPerimeterPoint.y),
        secondPerimeter: new Vector2(match.secondPerimeterPoint.x, match.secondPerimeterPoint.y)
    };
}

function getMatch(model: PunchModel, firstPerimeterPoints: Vector3[], secondPerimeterPoints: Vector3[]): { firstPerimeterPoint: Vector3; secondPerimeterPoint: Vector3 } | undefined {
    const length = (model.baseMaterial.compressionMemberId == CompressionMemberId.Rectangular) ? model.baseMaterial.punchLength / 2 : model.baseMaterial.punchDiameter / 2;
    const width = (model.baseMaterial.compressionMemberId == CompressionMemberId.Rectangular) ? model.baseMaterial.punchWidth / 2 : model.baseMaterial.punchDiameter / 2;
    const THRESHOLD = 2;

    // looking for successive element in top edge
    let filteredFirstPerimeterPoints = firstPerimeterPoints.filter(point => point.x >= -length && point.x <= length && point.y > 0).sort((a, b) => a.x - b.x);
    let filteredSecondPerimeterPoints = secondPerimeterPoints.filter(point => point.x >= -length && point.x <= length && point.y > 0).sort((a, b) => a.x - b.x);

    let match = filteredFirstPerimeterPoints
    .map((firstPoint) => {
        const secondPoint = filteredSecondPerimeterPoints.find(secondPoint => Math.abs(secondPoint.x - firstPoint.x) < THRESHOLD);
        return secondPoint ? { firstPerimeterPoint: firstPoint, secondPerimeterPoint: secondPoint } : null;
    })
    .find(result => result !== null);

    if (match != undefined) {
        return match;
    }

    // looking for successive element in left edge
    filteredFirstPerimeterPoints = firstPerimeterPoints.filter(point => point.y >= -width && point.y <= width && point.x < 0).sort((a, b) => a.y - b.y);
    filteredSecondPerimeterPoints = secondPerimeterPoints.filter(point => point.y >= -width && point.y <= width && point.x < 0).sort((a, b) => a.y - b.y);

    match = filteredFirstPerimeterPoints
    .map((firstPoint) => {
        const secondPoint = filteredSecondPerimeterPoints.find(secondPoint => Math.abs(secondPoint.y - firstPoint.y) < THRESHOLD);
        return secondPoint ? { firstPerimeterPoint: firstPoint, secondPerimeterPoint: secondPoint } : null;
    })
    .find(result => result !== null);

    if (match != undefined) {
        return match;
    }

    // looking for successive element in bottom edge
    filteredFirstPerimeterPoints = firstPerimeterPoints.filter(point => point.x >= -length && point.x <= length && point.y < 0).sort((a, b) => b.x - a.x);
    filteredSecondPerimeterPoints = secondPerimeterPoints.filter(point => point.x >= -length && point.x <= length && point.y < 0).sort((a, b) => b.x - a.x);

    match = filteredFirstPerimeterPoints
    .map((firstPoint) => {
        const secondPoint = filteredSecondPerimeterPoints.find(secondPoint => Math.abs(secondPoint.x - firstPoint.x) < THRESHOLD);
        return secondPoint ? { firstPerimeterPoint: firstPoint, secondPerimeterPoint: secondPoint } : null;
    })
    .find(result => result !== null);

    if (match != undefined) {
        return match;
    }

    // looking for successive element in right edge
    filteredFirstPerimeterPoints = firstPerimeterPoints.filter(point => point.y >= -width && point.y <= width && point.x > 0).sort((a, b) => b.y - a.y);
    filteredSecondPerimeterPoints = secondPerimeterPoints.filter(point => point.y >= -width && point.y <= width && point.x > 0).sort((a, b) => b.y - a.y);

    match = filteredFirstPerimeterPoints
        .map((firstPoint) => {
            const secondPoint = filteredSecondPerimeterPoints.find(secondPoint => Math.abs(secondPoint.y - firstPoint.y) < THRESHOLD);
            return secondPoint ? { firstPerimeterPoint: firstPoint, secondPerimeterPoint: secondPoint } : null;
        })
        .find(result => result !== null);

    if (match != undefined) {
        return match;
    }
    return undefined;
}

export function getMeasurementOffset(model: PunchModel, direction: PlaneDirection, firstConnector: Vector2): number | undefined {
    const length = (model.baseMaterial.compressionMemberId == CompressionMemberId.Rectangular) ? firstConnector.x : model.baseMaterial.punchDiameter / 2;
    const width = (model.baseMaterial.compressionMemberId == CompressionMemberId.Rectangular) ? firstConnector.y : model.baseMaterial.punchDiameter / 2;

    switch (direction) {
        case PlaneDirection.Bottom:
            return length;
        case PlaneDirection.Right:
            return width;
        case PlaneDirection.Top:
            return (model.baseMaterial.compressionMemberId == CompressionMemberId.Rectangular) ? length : -length;
        case PlaneDirection.Left:
            return (model.baseMaterial.compressionMemberId == CompressionMemberId.Rectangular) ? width : -width;
        default: return undefined;
    }
}

 export function getEdgeAndConnector(model: PunchModel, lastPerimeterPoints: Vector2[], disturbingEdge: Direction2d) {
    let sortedPerimeterPoints;
    if (disturbingEdge == Direction2d.Yp || disturbingEdge == Direction2d.Yn) {
        sortedPerimeterPoints = lastPerimeterPoints.sort((a, b) => a.y - b.y || a.x - b.x);
    }
    else {
        sortedPerimeterPoints = lastPerimeterPoints.sort((a, b) => a.x - b.x || a.y - b.y);
    }

    if (disturbingEdge == Direction2d.Xp || disturbingEdge == Direction2d.Yp) {
        sortedPerimeterPoints = sortedPerimeterPoints.reverse();
    }
    const translatedPoint = translateKernelPointTo3dPoint(model, sortedPerimeterPoints[0], 0);
    const connector = new Vector2(translatedPoint.x, translatedPoint.y);

    let edge;
    switch (disturbingEdge) {
        case Direction2d.Xn:
            edge = { x: -model.baseMaterial.spanNegX, y: connector.y };
            break;
        case Direction2d.Xp:
            edge = { x: model.baseMaterial.spanPosX, y: connector.y };
            break;
        case Direction2d.Yn:
            edge = { x: connector.x, y: -model.baseMaterial.spanNegY };
            break;
        case Direction2d.Yp:
            edge = { x: connector.x, y: model.baseMaterial.spanPosY };
            break;
        default:
            throw new Error('Unsupported edge');
    }
    return { connector: connector, edge: edge };
}
