@use '@profis-engineering/pe-ui-common/styles/app-settings-section.scss' as *;
@use '@profis-engineering/pe-ui-common/styles/dimensions.scss' as *;
@use '@profis-engineering/pe-ui-common/styles/colors.scss' as *;

.title-strike {
    margin-bottom: 16px;
    margin-top: 0px;
    font-weight: 700;
    color: $color-iron;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        top: 50%;
        margin-left: 8px;
        border-bottom: $separator-line-height solid $color-metal;
    }
}

.content-group .content .content-controls.region {
    align-items: flex-start;
}

.content-group .content.design-region > .content-controls > .local-regulations-disclaimer {
    flex: 1;
    background: #f3f2f0;
    color: #261d24;
    border: 1px solid #bab9ba;
    padding: 10px;
    margin: 10px 26px 0;
}

.design-standard-note {
    margin-top: 6px;
    margin-left: 2px;
    margin-right: 2px;
    color: #999;
}

.content-group .content .content-controls .control.design-standard-dropdown {
    display: block;
    margin-left: 0;
}
