@use './colors.scss' as *;
@use './dimensions.scss' as *;

.section {
    margin-bottom: 32px;

    &:last-child {
        margin-bottom: 0;
    }

    .control-row {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;

        &:last-child {
            margin-bottom: 0;
        }

        .input {
            width: 100%;
        }

        &.multiple {
            flex-direction: row;
            .control-column {
                flex: 1;
                min-width: 0;
                margin-right: 16px;
                display: flex;
                flex-direction: column;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .section-row {
        display: flex;

        .section-column {
            flex: 1;
            min-width: 0;
            padding: 0 16px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                &.separator {
                    border-right: none;
                }
            }

            &.separator {
                margin-top: -16px;
                padding-top: 16px;
                margin-bottom: -16px;
                padding-bottom: 16px;
                border-right: 1px solid $color-metal;
            }
        }

        .size2 {
            flex: 2 !important;
        }

        .size3 {
            flex: 3 !important;
        }
    }

    .title-strike {
        margin-bottom: 16px;
        margin-top: 0px;
        font-weight: 700;
        color: $color-iron;
        position: relative;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            top: 50%;
            margin-left: 8px;
            border-bottom: $separator-line-height solid $color-metal;
        }
    }
}
