@if (this.utilizationsAvailable) {
    <div id="utilizations" class="utilizations box-section box-section-white">
        <div [id]="getComponentId('utilization')">
            @if (utilizations) {
                @for (section of utilizations!.sections; track section.id; let sectionIndex = $index) {

                    @if (showPrimaryTab()) {
                        <div class="box-section-header box-section-header-with-img" [class]="typeClass(TabType.Primary)">

                            <div class="box-section-title drag-handle-static utilization-title-wrapper" [ngbTooltip]="section.tooltipText">
                                @if (section.iconName != null) {
                                    <div class="image" [id]="getComponentId('utilization-image')">
                                        <div class="pe-ui-sp-sprite pe-ui-sp-sprite-tab-{{section.iconName}}"></div>
                                    </div>
                                }

                                <span class="bar-subtitle"></span>
                                <span class="bar-title" [id]="getComponentId('utilization-header')">
                                    {{ translate(section.title) }}

                                    @if (showNewLabel) {
                                        <span class="new-label" l10n="Common.Tags.New"></span>
                                    }
                                </span>
                                <span class="bar-subtitle"></span>
                            </div>

                            <pe-utilization-bar
                                [id]="getComponentId('utilization-bar')"
                                [collapsed]="getIsCollapsed(section.id)"
                                [utilizationInvalid]="!section.isMaxUtilizationValid"
                                [utilizationValue]="roundValue(section.maxUtilization)" />
                            <pe-collapse
                                [id]="section.id"
                                [collapsed]="getIsCollapsed(section.id)"
                                (collapsedChange)="onCollapseChanged($any($event).detail, section.id)" />
                        </div>
                    }

                    @for (subsection of section.subsections; track subsection.id; let subsectionIndex = $index) {
                        @if (subsection.isVisible && (!showPrimaryTab() || !getIsCollapsed(section.id))) {
                            <div [id]="getComponentId('secondary-type', sectionIndex)"
                                class="box-section-header box-section-header-with-img"
                                [class]="showPrimaryTab() ? typeClass(TabType.Secondary) : typeClass(TabType.Primary)">

                                <div class="box-section-title drag-handle-static utilization-title-wrapper" [ngbTooltip]="null">
                                    @if (subsection.iconName != null) {
                                        <div class="pe-ui-sp-sprite pe-ui-sp-sprite-tab-{{subsection.iconName}}">
                                        </div>
                                    }

                                    <span class="bar-title" [id]="getComponentId('utilization-details-header', sectionIndex)">
                                        {{ translate(subsection.title) }}

                                        @if (showNewLabel) {
                                            <span class="new-label" l10n="Common.Tags.New"></span>
                                        }
                                    </span>

                                    <span class="bar-subtitle"></span>
                                </div>

                                <pe-utilization-bar
                                    [collapsed]="getIsCollapsed(subsection.id)"
                                    [utilizationInvalid]="!subsection.isMaxUtilizationValid"
                                    [utilizationValue]="roundValue(subsection.maxUtilization)"
                                    [id]="getComponentId('utilization-bar', subsectionIndex)" />
                                <pe-collapse
                                    [id]="subsection.id" [collapsed]="getIsCollapsed(subsection.id)"
                                    (collapsedChange)="onCollapseChanged($any($event).detail, subsection.id)"/>
                            </div>

                            @if (!getIsCollapsed(subsection.id) &&
                                subsection.generalUtilizationItems != null &&
                                subsection.generalUtilizationItems.length > 0) {

                                <div class="items box-section-content">
                                    @for (utilizationItem of subsection.generalUtilizationItems; track utilizationItem.id; let utilizationIndex = $index) {
                                        <div [id]="getComponentId('utilization-group-details-property', subsectionIndex, utilizationIndex)">
                                            <app-utilization-item
                                                [title]="getPropertyTitle(utilizationItem.title, utilizationItem.utilizationVariable)"
                                                [controlId]="getComponentId('utilization-detail-worst', subsectionIndex, utilizationIndex)!"
                                                [utilizationItem]="utilizationItem">
                                            </app-utilization-item>
                                        </div>
                                    }
                                </div>
                            }
                        }
                    }
                }
            }
        </div>
    </div>
}
