@use '@profis-engineering/pe-ui-common/styles/colors.scss' as *;
@use '@profis-engineering/pe-ui-common/styles/fonts.scss' as *;
@use '@profis-engineering/pe-ui-common/styles/bootstrap-dropdown.scss' as *;
@use '@profis-engineering/pe-ui-common/styles/bootstrap-override.scss' as *;

@use '../../../introjs.css';
@use '@profis-engineering/pe-ui-common/styles/box-section.scss';

:host {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background: #fff;
}

.main-content {
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    overflow-x: hidden;
}

.main-content-left {
    background: $color-white;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex: 0 0 321px;

    &.width-extended {
        flex: 0 0 550px;
    }
}

.main-content-left-container {
    border-right: 1px solid $color-medium-gray;
    display: flex;
    flex-grow: 1;
    position: relative;
}

.main-content-left-black-bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 48px;
    background: $color-iron;
}

.menu-container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.main-content-left-hidden {
    flex: 0 0 48px;
}

.main-content-center-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: hidden;
    max-height: calc(100vh - 48px);
}

.main-content-center-right-top {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
}

.main-content-center {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow-y: hidden;
    position: relative;
}

.main-content-position {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.model-view-options {
    margin: 12px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
    z-index: 1;
}

.options-left {
    flex: 1;
    flex-wrap: wrap;
    min-width: 0;
    display: flex;
    position: relative;
    justify-content: flex-start;
}

.update-pending {
    display: none;
    margin: 8px;
}

.update-pending.show {
    display: block;
}

.hide-menu-button {
    position: absolute;
    z-index: 1;
    bottom: 12px;
}

.hide-arrow-sprite {
    margin: 0 3px;
}

.hide-menu-button-left {
    left: 12px;
}

.hide-menu-button-right {
    right: 12px;
}

.click-through {
    pointer-events: none;
}

.main-content-right-sidebar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 272px;
    border-left: 1px solid $color-medium-gray;
    background: $color-white;

    .main-content-right {
        display: flex;
        flex-direction: column;
        height: 100%;

        .main-content-right-container {
            position: relative;
            display: flex;
            flex-grow: 1;

            .main-content-right-scroll {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                overflow-x: hidden;
                overflow-y: auto;
                flex-direction: column;
                display: flex;

                .main-content-right-util {
                    z-index: 9;

                    .box-section {
                        margin-top: 1px;
                    }
                }

                .control-row {
                    margin-top: 8px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .space {
                    flex-grow: 1;
                    min-height: 0;
                }

                .z-index-one {
                    z-index: 1;
                }

                .box-section-header,
                .box-section-header-with-img {
                    height: auto;
                    padding: 0 4px;
                }

                .bar-title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    height: auto;
                    flex: 1;
                }

                .bar-subtitle {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    height: auto;
                    font-size: 11px;
                    text-transform: none;
                    padding: 4px;
                }

                .bar-length {
                    flex: 0 0 60px;
                    margin: 0;
                }
            }
        }
    }

}

.button-group {
    margin: 0 4px 10px 4px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    .pe-ui-sp-sprite {
        margin: -1px 3px -1px 3px;
    }
}

.click-reset {
    pointer-events: auto;
}

.join {
    div:not(:first-child) {
        margin-left: 2px;
    }
}

.buttons.join {
    display: flex;
}

.tooltip-wrapper {
    display: inline-block;

    .button[disabled] {
        pointer-events: none;
    }
}

#main-redo-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .pe-ui-sp-sprite {
        opacity: 1;
    }
}

#main-undo-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .pe-ui-sp-sprite {
        opacity: 1;
    }
}

#display-options-dropdown {
    white-space: nowrap;
    z-index: 100;
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 8px 8px 4px 8px;
    flex-direction: column;
    border-radius: 0;
    font-size: 12px;

    .checkbox {
        margin: 4px 0;
    }
}

.model-view-container {
    flex-grow: 1;
    position: relative;
}

.model-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
