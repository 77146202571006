import { Color3, Color4 } from '@babylonjs/core/Maths/math.color';
import { Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TooltipKey as BaseTooltipKey } from '@profis-engineering/gl-model/external/tooltip';
import { ICameraValues3d, LoadsVisibilityInfo } from '@profis-engineering/gl-model/gl-model';
import { DeepPartial } from 'ts-essentials';
import { PunchDesignDetails, PunchOuterPerimeter, PunchPerimeter, PunchPropertyId } from '../services/design.service';
import { CompressionMemberElement } from './components/punch/compression-member';
import { PunchMeasurements } from './components/punch/measurements/punch-measurements';
import { PunchPostInstalledElement } from './components/punch/post-installed-element';
import { PunchBaseMaterialElement } from './components/punch/punch-base-material';
import { PunchCoordinateSystem } from './components/punch/punch-coordinate-system';
import { PunchOpening } from './components/punch/punch-opening';
import { PunchSceneCoordinateSystem } from './components/punch/punch-scene-coordinate-system';
import {
    BaseUpdate,
    GlModel,
    Model,
    ModelUpdateClass,
    ScreenshotSettings,
    UnitText2D
} from './gl-model';

export interface PunchModel extends Model {
    baseMaterial: PunchBaseMaterialModel;
    visibilityModel: VisibilityModel;
    punchPostInstalledElement: PunchPostInstalledElementModel;
}

export type PunchPartialModel = DeepPartial<PunchModel>;
export type PunchUnitText2D = UnitText2D<PunchPropertyId>;

export interface VisibilityModel {
    TransparentConcrete: boolean;
    ConcreteDimensionsVisible: boolean;
    CriticalPerimeter: boolean;
    StrengtheningElementSpacingDimensions: boolean;
    StrengtheningElementEdgeDistanceDimensions: boolean;
    OpeningNumbering: boolean;
    OpeningDimensions: boolean;
}

export const enum CompressionMemberId {
    Rectangular = 1,
    Circular = 2
}

export interface PunchBaseMaterialModel {
    baseMemberId: number;
    punchLength: number;
    punchWidth: number;
    punchDiameter: number;
    compressionMemberId: CompressionMemberId;
    spanNegX: number;
    spanPosX: number;
    spanNegY: number;
    spanPosY: number;
    thickness: number;
    openingsNumberId: number;
    punchOpening1Length: number;
    punchOpening2Length: number;
    punchOpening3Length: number;
    punchOpening1Width: number;
    punchOpening2Width: number;
    punchOpening3Width: number;
    punchOpening1OriginX: number;
    punchOpening2OriginX: number;
    punchOpening3OriginX: number;
    punchOpening1OriginY: number;
    punchOpening2OriginY: number;
    punchOpening3OriginY: number;
}

export interface PunchPostInstalledElementModel {
    defineStrengtheningElement: boolean;
    anchorDiameter: number;
    depthOfRecess: number;
    perimeters: PunchPerimeter[];
    controlPerimeters: Vector2[][] | undefined;
    influenceVectors: Vector2[] | undefined;
    outerPerimeters: PunchOuterPerimeter | undefined;
    drillLength: number;
    punchInstallationDirectionId: number;
}
export type PunchTooltipKey = BaseTooltipKey | 'BaseMaterial';

export abstract class PunchBaseUpdate extends BaseUpdate<PunchPropertyId> { }
export type PunchModelUpdateClass = ModelUpdateClass<PunchPropertyId>;

export interface PunchModelUpdate {
    PunchBaseMaterialElementUpdate?: PunchModelUpdateClass;
    CompressionMemberElementUpdate?: PunchModelUpdateClass;
    PunchPostInstalledElementUpdate?: PunchModelUpdateClass;
}

export class PunchGlModel extends GlModel<
    PunchModel,
    PunchPropertyId,
    PunchTooltipKey,
    PunchModelUpdate,
    PunchDesignDetails
> {
    protected override initializeComponentDefinitions(modelUpdate: PunchModelUpdate): void {
        this.componentDefinitions = [
            {
                componentType: PunchBaseMaterialElement,
                options: {
                    updateModelCtor: modelUpdate?.PunchBaseMaterialElementUpdate,
                },
            },
            {
                componentType: CompressionMemberElement,
                options: {
                    updateModelCtor: modelUpdate?.CompressionMemberElementUpdate,
                },
            },
            {
                componentType: PunchPostInstalledElement,
                options: {
                    updateModelCtor: modelUpdate?.PunchPostInstalledElementUpdate,
                },
            },
            {
                componentType: PunchMeasurements,
            },
            {
                componentType: PunchSceneCoordinateSystem,
            },
            {
                componentType: PunchCoordinateSystem,
            },
            {
                componentType: PunchOpening,
                options: {
                    updateModelCtor: modelUpdate?.PunchBaseMaterialElementUpdate,
                },
            }
        ];
    }

    protected override calculateCameraValues3d(): ICameraValues3d {
        return {
            alpha: Math.PI / 6,
            beta: Math.PI / 8,
            radius: 18500,
            betaMin: -Math.PI / 2 + 0.01,
            betaMax: Math.PI / 2 - 0.01,
            radiusMin: 1000,
            radiusMax: 30000,
        };
    }

    protected override setLights(): void {
        this.frontLight.direction = new Vector3(0.35, 4, 1);
        this.frontLight.specular = Color3.Black();
        this.frontLight.intensity = 0.575;

        this.backLight.direction = new Vector3(-0.35, -4, -1);
        this.backLight.specular = Color3.Black();
        this.backLight.intensity = 0.575;

        this.groundLight.direction = Vector3.Zero();
        this.groundLight.specular = Color3.Black();
        this.groundLight.diffuse = Color3.White();
        this.groundLight.groundColor = Color3.White();
        this.groundLight.intensity = 0.4;
    }

    protected override ensureScreenShotSettingsInternal(): Vector3 {
        // TODO TEAM: what is this magic number?
        return new Vector3(4420, -8840, -7650);
    }

    /**
     * Method sets visibility for scene meshes for generating screenshot
     */
    protected override prepareVisibilityForScreenshot(
        zoomed: boolean,
        info: LoadsVisibilityInfo,
        preview: boolean,
        extraInfo: ScreenshotSettings
    ) {
        // no highlighting
        for (const highlightedDimension in this.model.highlightedDimensions) {
            this.model.highlightedDimensions[highlightedDimension] = false;
        }

        this.scene3d.clearColor = new Color4(1, 1, 1, 1);

        if (extraInfo.isThumbnail) {
            this.scene3d.clearColor = new Color4(0.933, 0.933, 0.933, 1);

            if (this.model.sceneCoordinateSystem != null) {
                this.model.sceneCoordinateSystem.hidden = true;
            }
        }
        else if (extraInfo.preview) {
            if (this.model.sceneCoordinateSystem != null) {
                this.model.sceneCoordinateSystem.hidden = true;
            }
        }

        // Shows or hides the coordinate system, along with some minor extra settings.
        // (+) In actual report, coordinate system should be visible.
        // (+) On report export dialog and home page, don't show coordinate system.
        this.model.sceneCoordinateSystem!.report = true;
        this.model.screenshot = true;

        // Enabling the visibility of all elements.
        this.model.visibilityModel.TransparentConcrete = true;
        this.model.visibilityModel.ConcreteDimensionsVisible = true;
        this.model.visibilityModel.CriticalPerimeter = true;
        this.model.visibilityModel.StrengtheningElementSpacingDimensions = true;
        this.model.visibilityModel.StrengtheningElementEdgeDistanceDimensions = true;
        this.model.visibilityModel.OpeningNumbering = true;
        this.model.visibilityModel.OpeningDimensions = true;

        this.refreshComponents();
    }
}
