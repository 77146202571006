import { Direction2d } from '../../../../services/design.service';
import { CompressionMemberId, PunchModel } from '../../../punch-gl-model';
import { PunchMeasurementPlaneRowData } from './punch-measurements';

interface Point {
     x: number;
     y: number;
};

export type CustomPropertyIds = 'edgeDistanceX' | 'edgeDistanceY';

export const enum PlaneDirection {
    Bottom = 0,
    Right = 1,
    Top = 2,
    Left = 3,
}

export function SpanXMeasurement(model: PunchModel): PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible,
        measurements: [
            {
                x1: 0,
                x2: model.baseMaterial.spanPosX,
                y1: -model.baseMaterial.spanNegY,
                y2: -model.baseMaterial.spanNegY,
                property: 'spanPosX',
                custom: false
            },
            {
                x1: -model.baseMaterial.spanNegX,
                x2: 0,
                y1: -model.baseMaterial.spanNegY,
                y2: 0,
                property: 'spanNegX',
                custom: false
            }
        ]
    };
}

export function PerimeterEdgeSpacingMeasurement(model: PunchModel, connector: Point, edge: Point, disturbingEdge: Direction2d): PunchMeasurementPlaneRowData {
    let edgeDistance;
    let property;
    if ((disturbingEdge === Direction2d.Xn || disturbingEdge === Direction2d.Xp)) {
        edgeDistance = Math.abs(edge.x - connector.x);
        property = 'edgeDistanceX';
    }
    else {
        edgeDistance = Math.abs(edge.y - connector.y);
        property = 'edgeDistanceY';
    }


    // We are using YX plane instead of XY, so we have to change coordinates
    if (disturbingEdge === Direction2d.Yn || disturbingEdge === Direction2d.Yp) {
        connector = swapCoordinates(connector);
        edge = swapCoordinates(edge);
    }

    let point1 = connector;
    let point2 = edge;

    if (disturbingEdge === Direction2d.Xp || disturbingEdge === Direction2d.Yp) {
        const tmp = point1;
        point1 = point2;
        point2 = tmp;
    }

    return {
        enabled: model.visibilityModel.StrengtheningElementEdgeDistanceDimensions,
        measurements: [
            {
                x1: point2.x,
                x2: point1.x,
                y1: point2.y,
                y2: point1.y,
                property: property as CustomPropertyIds,
                custom: { value: edgeDistance },
            },
        ]
    };
}


export function PerimeterSpacingMeasurement(model: PunchModel, closestEdgePoint: Point, firstPerimeter: Point, secondPerimeter: Point, direction: PlaneDirection): PunchMeasurementPlaneRowData {
    // We are using YX plane instead of XY, so we have to change coordinates
    if (direction === PlaneDirection.Bottom || direction === PlaneDirection.Top) {
        firstPerimeter = swapCoordinates(firstPerimeter);
        secondPerimeter = swapCoordinates(secondPerimeter);
        closestEdgePoint = swapCoordinates(closestEdgePoint);
    }

    let point1 = firstPerimeter;
    let point2 = closestEdgePoint;

    let point3 = secondPerimeter;
    let point4 = firstPerimeter;

    if (direction == PlaneDirection.Right || direction == PlaneDirection.Top) {
        let tmp = point1;
        point1 = point2;
        point2 = tmp;

        tmp = point3;
        point3 = point4;
        point4 = tmp;
    }

    return {
        enabled: model.visibilityModel.StrengtheningElementSpacingDimensions && model.punchPostInstalledElement.defineStrengtheningElement,
        measurements: [
            {
                x1: point1.x,
                x2: point2.x,
                y1: point1.y,
                y2: point2.y,
                property: 'firstPerimeterSpacing',
                custom: false
            },
            {
                x1: point3.x,
                x2: point4.x,
                y1: point3.y,
                y2: point4.y,
                property: 'subsequentPerimeterSpacing',
                custom: false
            }
        ]
    };
}

export function SpanYMeasurement(model: PunchModel): PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible,
        measurements: [
            {
                x1: 0,
                x2: model.baseMaterial.spanPosY,
                y1: model.baseMaterial.spanPosX,
                y2: model.baseMaterial.spanPosX,
                property: 'spanPosY',
                custom: false
            },
            {
                x1: -model.baseMaterial.spanNegY,
                x2: 0,
                y1: model.baseMaterial.spanPosX,
                y2: 0,
                property: 'spanNegY',
                custom: false
            }
        ]
    };
}

export function ThicknessMeasurement(model: PunchModel): PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible,
        measurements: [
            {
                x1: -model.baseMaterial.thickness / 2,
                x2: model.baseMaterial.thickness / 2,
                y1: model.baseMaterial.spanPosX,
                y2: model.baseMaterial.spanPosX,
                property: 'thickness',
                custom: false
            }
        ]
    };
}


export function PunchLengthMeasurement(model: PunchModel): PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible && model.baseMaterial.compressionMemberId != CompressionMemberId.Circular,
        measurements: [
            {
                x1: -model.baseMaterial.punchLength / 2,
                x2: model.baseMaterial.punchLength / 2,
                y1: -model.baseMaterial.punchWidth / 2,
                y2: -model.baseMaterial.punchWidth / 2,
                property: 'punchLength',
                custom: false
            }
        ]
    };
}

export function PunchWidthMeasurement(model: PunchModel): PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible && model.baseMaterial.compressionMemberId != CompressionMemberId.Circular,
        measurements: [
            {
                x1: -model.baseMaterial.punchWidth / 2,
                x2: model.baseMaterial.punchWidth / 2,
                y1: model.baseMaterial.punchLength / 2,
                y2: model.baseMaterial.punchLength / 2,
                property: 'punchWidth',
                custom: false
            }
        ]
    };
}

export function PunchOpeningMeasurement(openingNumber: number, model: PunchModel, isLengthMeasurement: boolean): PunchMeasurementPlaneRowData {
    const baseMaterial = model.baseMaterial;
    const lengthProp = `punchOpening${openingNumber}Length` as keyof typeof baseMaterial;
    const widthProp = `punchOpening${openingNumber}Width` as keyof typeof baseMaterial;
    const originXProp = `punchOpening${openingNumber}OriginX` as keyof typeof baseMaterial;
    const originYProp = `punchOpening${openingNumber}OriginY` as keyof typeof baseMaterial;

    if (isLengthMeasurement) {
        return {
            enabled: model.visibilityModel.OpeningDimensions && baseMaterial.openingsNumberId > openingNumber,
            measurements: [
                {
                    x1: -baseMaterial[lengthProp] / 2 + baseMaterial[originXProp],
                    x2: baseMaterial[lengthProp] / 2 + baseMaterial[originXProp],
                    y1: baseMaterial[originYProp] + baseMaterial[widthProp] / 2 +
                        (baseMaterial[originYProp] <= 0 ? -baseMaterial[widthProp] : 0),
                    y2: baseMaterial[originYProp] + baseMaterial[widthProp] / 2 +
                        (baseMaterial[originYProp] <= 0 ? -baseMaterial[widthProp] : 0),
                    property: lengthProp,
                    custom: false,
                },
            ],
        };
    }
    else {
        return {
            enabled: model.visibilityModel.OpeningDimensions && baseMaterial.openingsNumberId > openingNumber,
            measurements: [
                {
                    x1: -baseMaterial[widthProp] / 2 + baseMaterial[originYProp],
                    x2: baseMaterial[widthProp] / 2 + baseMaterial[originYProp],
                    y1: baseMaterial[originXProp] + baseMaterial[lengthProp] / 2 +
                        (baseMaterial[originXProp] <= 0 ? -baseMaterial[lengthProp] : 0),
                    y2: baseMaterial[originXProp] + baseMaterial[lengthProp] / 2 +
                        (baseMaterial[originXProp] <= 0 ? -baseMaterial[lengthProp] : 0),
                    property: widthProp,
                    custom: false,
                },
            ],
        };
    }
}

function swapCoordinates(firstPerimeter: Point): Point {
    return { x: firstPerimeter.y, y: firstPerimeter.x };
}

