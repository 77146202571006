﻿@import './colors.scss';

.box-section {
    background: $color-white;

    & .all-designs {
        .box-section-title {

            .box-section-title-text {
                flex: 0 0 auto;
                text-transform: none;
            }

            .project-name {
                text-overflow: ellipsis;
                overflow-x: hidden;
                white-space: nowrap;
                text-transform: none;
            }
        }
    }

    &.box-section-white {
        background: $color-white;
    }

    .box-section-header,
    .box-section-header-with-img {
        margin-top: 1px;
        height: 32px;
        padding: 8px 0 8px 8px;
        color: $color-iron;
        font-weight: 700;
        background: $color-light-gambogeish-gray;
        display: flex;
        align-items: center;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        user-select: none; /* non-prefixed version, currently not supported by any browser */

        .box-section-title {
            flex: 1;
            min-width: 0;
            display: flex;
            align-items: center;
            height: 32px;
            text-transform: uppercase;

            .button {
                height: 24px;
                min-height: 24px;
            }
        }

        .collapse-button {
            height: 32px;
            overflow: hidden;
            flex: 0 0 32px;
        }
    }

    .box-section-content {
        padding: 16px;

        &.box-section-white {
            background: $color-white;
        }
    }

    &:first-child {
        .box-section-header,
        .box-section-header-with-img {
            margin-top: 0;
        }
    }
}
