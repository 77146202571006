@use '@profis-engineering/pe-ui-common/styles/colors.scss' as *;
@use '@profis-engineering/pe-ui-common/styles/section.scss' as *;
@use '@profis-engineering/pe-ui-common/styles/fonts.scss' as *;

.section {
    padding-right: 8px;

    &.summary {
        margin-bottom: 0;
    }

    &.comment-and-notes {
        margin-bottom: 0;
    }
}

.section:last-child {
    padding-bottom: 8px;
}

.comment-and-notes .input{
    resize:vertical;
    min-height: 18px;
}

.summary > .section-row {
    .summary-left.section-column {
        flex: 1;
    }

    .summary-right.section-column {
        flex: 2;

        .summary-right-top {
            margin-bottom: 16px;
        }
    }

    .model-image-wrapper {
        min-width: 0;

        .model-image {
            max-width: 100%;
        }
    }

    .anchor-image-wrapper {
        min-width: 0;
        margin-top: 5px;

        .anchor {
            max-width: 100%;
        }
    }
}

.title-strike {
    height: 32px;
    line-height: 32px;
    margin-bottom: 4px;

    font-weight: 700;
    color: #524f53;
    position: relative;
    overflow: hidden;

    margin-top: 0;

    .section-collapse {
        border: none;
        background: none;
        padding: 0;
        position: absolute;
        right: 0;
        z-index: 10;
        background-color: white;
    }

    &.no-margin {
        margin-bottom: 0;
    }
}

.title-strike:after {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    margin-left: 8px;
    border-bottom: thin solid #ccc
}

.disabled-section {
    pointer-events: none;
    color: $color-gray;
    border-color: $color-gray;
}

.hidden {
    display: none !important;
}
.section-pictures{
    display: flex;
    width: 100%;
    flex: 1 0 0;
    height: 100%;
}
.main-content-container {
    flex: 1 0 0;
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;
    width: 33%;

    .row-control .picture-container {
        display: flex;
        flex-direction: column;

        .picture-image {
            flex: 1 0 0;
            margin-right: 16px;

            .picture-image-img-wrapper {
                width: 174px;
                height: 100px;
                border: 1px solid $color-gray;
                margin: 0;
                position: relative;
                margin-bottom: 10px;

                img {
                    max-width: 70%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }

                .picture-image-btn-remove {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 24px;
                    height: 24px;

                    &[disabled] {
                        cursor: not-allowed;
                    }
                }
            }
        }

        .picture-upload {
            flex: 1 0 0;
            margin-right: 16px;
            width: 100%;

            .tooltip-wrapper {
                display: inline-block;

                .picture-upload-button {
                    margin: 0;
                    min-width: 176px;
                }

                .picture-upload-button[disabled] {
                    pointer-events: none;
                }
            }

            .picture-upload-input {
                display: none;
            }
        }
    }
}

.picture-upload-description {
    color: $color-gray;
    margin-top: 20px;
    margin-left: 16px;
}

button:not(:disabled) {
    cursor: pointer;
}

button[disabled] {
    cursor: not-allowed;
}

.control-label {
    color: $color-0x887f6e;
    padding-bottom: 4px;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    margin-top: 0px;
}
