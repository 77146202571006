@use './colors.scss' as *;
@use './fonts.scss' as *;

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-item:focus {
    background-color: transparent;
}

.dropdown-item:disabled {
    pointer-events: all;
    cursor: not-allowed;
}

.dropdown-menu {
    border-radius: 0;
    background-color: $color-silver;
    padding: 2px 0;
}

.dropdown-item, .dropdown-item:active {
    font-size: 12px;
    color: $color-iron;
    padding: 3px 6px;
}

.dropdown-item:hover, .dropdown-item:active {
    background-color: $color-light-gray;
}

.dropdown-menu > .dropdown {
    padding-left: 3px;
}

.dropdown-menu > .dropdown:hover {
    cursor: pointer;
    background-color: $color-light-gray;
}

.dropdown-menu > .dropdown > button {
    display: flex;
}

.dropdown-menu > .dropdown > button::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 6.9px 4px 0;
    border-color: transparent #534F53 transparent transparent;
    margin: 5px 5px 0 -2px;
}

.modal-header button.close {
    background: none;
    border: none;
    font-weight: 700;
}

.dropdown-menu-container > .dropdown-toggle:focus {
    outline: auto 5px -webkit-focus-ring-color;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.tooltip {
    font-family: $font-hilti;
    font-size: 12px;
}

.tooltip .tooltip-inner {
    word-wrap: break-word;
    border-radius: 0;
    background: $color-licorice;
    padding: 4px 8px;
    color: $color-metal;
}

.hidden {
    display: none !important;
}

.modal-open .modal-footer {
    flex-wrap: nowrap;
}

.modal-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.modal-dialog {

    .modal.modal-static & {
        transform: none;
    }
}
