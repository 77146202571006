import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Matrix, Path2, Vector3 } from '@babylonjs/core/Maths/math';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { CreateCylinder } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { FloatArray, Nullable } from '@babylonjs/core/types';
import { PolygonBuilder } from '@profis-engineering/gl-model/polygon-builder';
import { cloneVertexData } from '@profis-engineering/gl-model/vertex-data-helper';
import isEqual from 'lodash-es/isEqual';
import { PunchComponent, PunchComponentConstructor } from '../../punch-component';
import { CompressionMemberId, PunchBaseMaterialModel, VisibilityModel } from '../../punch-gl-model';
import { baseMaterialDarkColor } from './punch-base-material';
import { baseMaterialAlphaIndex } from './punch-helper';
import { CreateRibbonVertexData } from '@babylonjs/core/Meshes/Builders/ribbonBuilder';

interface PunchCompressionMemberModelUpdateData {
    visibilityInfo: VisibilityModel;
    baseMaterial: PunchBaseMaterialModel;
    lengthInX: number;
    widthInY: number;
    punchDiameter: number;
    compressionMemberId: number;
}

export const constants = {
    compressionHeight: 1000,
    infinityPointsNumber: 100,
    infinitySize: 6,
    infinityOffset: 0.5,
    scaleFactor: 30,
    boxSize: 1,
    point: 0.5
};

export class CompressionMemberElement extends PunchComponent {
    private compressionMaterial!: StandardMaterial;
    private compressionMemberModelUpdateData: PunchCompressionMemberModelUpdateData;

    private rectangularMesh: Mesh | undefined;
    private circularMesh: Mesh | undefined;

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);
        this.compressionMemberModelUpdateData = {} as PunchCompressionMemberModelUpdateData;

        this.initMaterial();
    }

    private initMaterial() {
        this.compressionMaterial = new StandardMaterial('CompressionMemberMaterial', this.scene);
        this.compressionMaterial.diffuseColor = baseMaterialDarkColor;
    }

    public update(): void {
        const modelUpdateData = {
            visibilityInfo: {
                TransparentConcrete: this.model.visibilityModel.TransparentConcrete,
                CriticalPerimeter: this.model.visibilityModel.CriticalPerimeter,
                StrengtheningElementSpacingDimensions: this.model.visibilityModel.StrengtheningElementSpacingDimensions,
                StrengtheningElementEdgeDistanceDimensions: this.model.visibilityModel.StrengtheningElementEdgeDistanceDimensions,
            },
            baseMaterial: this.model.baseMaterial,
            lengthInX: this.model.baseMaterial.punchLength,
            widthInY: this.model.baseMaterial.punchWidth,
            punchDiameter: this.model.baseMaterial.punchDiameter,
            compressionMemberId: this.model.baseMaterial.compressionMemberId,
        } as PunchCompressionMemberModelUpdateData;

        if (isEqual(this.compressionMemberModelUpdateData, modelUpdateData)) {
            return;
        }
        this.compressionMemberModelUpdateData = structuredClone(modelUpdateData);
        this.ensureMesh();
    }

    private ensureMesh() {
        this.rectangularMesh?.setEnabled(false);
        this.circularMesh?.setEnabled(false);

        if (this.model.baseMaterial.compressionMemberId == CompressionMemberId.Rectangular) {
            this.createRectangularCompressionMember();
        }
        else {
            this.createCircularCompressionMember();
        }
    }

    private getInfinityPoints() {
        return this.cache.commonCache.create(`PunchCompressionMember_InfinityPoints`, () => {
            const xPlusPoints: Vector3[] = [];
            const xMinusPoints: Vector3[] = [];

            for (let point = 0; point <= constants.infinityPointsNumber * constants.infinitySize; point++) {
                const x = point / constants.infinityPointsNumber;
                const z = Math.cos(x * Math.PI);
                const boxX = (x / constants.infinitySize) * constants.boxSize - this.boxSizeHalf;
                const boxZ = -z * constants.infinityOffset - this.boxSizeHalf + constants.infinityOffset;

                xPlusPoints.push(new Vector3(boxX, this.boxSizeHalf, boxZ / constants.scaleFactor - constants.infinityOffset));
                xMinusPoints.push(new Vector3(boxX, -this.boxSizeHalf, boxZ / constants.scaleFactor - constants.infinityOffset));
            }

            return { xMinusPoints: xMinusPoints, xPlusPoints: xPlusPoints };
        });
    }

    private createRectangularCompressionMember() {
        this.rectangularMesh = this.cache.meshCache.create('PunchCompressionMemberRectangle', () => {
            const meshVertexData = new VertexData();
            meshVertexData.positions = [];
            meshVertexData.indices = [];
            meshVertexData.normals = [];

            meshVertexData.merge(this.face_xPlus);
            meshVertexData.merge(this.face_xMinus);
            meshVertexData.merge(this.face_yPlus);
            meshVertexData.merge(this.face_yMinus);
            meshVertexData.merge(this.face_top);
            meshVertexData.merge(this.face_bottom_infinite);

            // APPLY ALL FACES TO MESH
            const mesh = new Mesh('PunchCompressionMemberRectangle', this.scene);
            mesh.rotation.x = Math.PI / 2;
            meshVertexData.applyToMesh(mesh);

            return mesh;
        });

        this.setMeshProperties(this.rectangularMesh);
    }

    private createCircularCompressionMember() {
        this.circularMesh = this.cache.meshCache.create('CompressionMemberMeshCylinder', () => {
            const mesh = CreateCylinder('CompressionMemberMeshCylinder', {
                tessellation: 24,
                height: 1

            }, this.scene);
            mesh.rotation.x = Math.PI;
            return mesh;
        });

        this.setMeshProperties(this.circularMesh);
    }

    private setMeshProperties(mesh: Mesh) {
        this.compressionMaterial.alpha = this.model.visibilityModel.TransparentConcrete ? 0.5 : 1;

        mesh.material = this.compressionMaterial;
        mesh.alphaIndex = baseMaterialAlphaIndex;

        if (this.model.baseMaterial.compressionMemberId == CompressionMemberId.Rectangular) {
            mesh.scaling.x = this.model.baseMaterial.punchLength;
            mesh.scaling.y = this.model.baseMaterial.punchWidth;
            mesh.scaling.z = constants.compressionHeight;
        }
        else {
            mesh.scaling.x = this.model.baseMaterial.punchDiameter;
            mesh.scaling.y = constants.compressionHeight;
            mesh.scaling.z = this.model.baseMaterial.punchDiameter;
        }

        mesh.position = new Vector3(
            0,
           -(constants.compressionHeight / 2 + this.model.baseMaterial.thickness / 2),
            0
        );

        mesh.isVisible = true;
        mesh.setEnabled(true);

        return mesh;
    }


    private get face_base() {
        return this.cache.vertexDataCache.create(`compression_face_base`, () => {
            const positions = [
                constants.point, constants.point, -constants.point,
                constants.point, -constants.point, -constants.point,
                constants.point, -constants.point, constants.point,
                constants.point, constants.point, constants.point,
            ];
            const indices = [
                0, 1, 2,
                0, 2, 3,
            ];

            const normals: Nullable<FloatArray> = [];

            // Calculations of normals added
            VertexData.ComputeNormals(positions, indices, normals);

            const vertexData = new VertexData();
            vertexData.positions = positions;
            vertexData.indices = indices;
            vertexData.normals = normals;

            return vertexData;
        });
    }
    private get face_xPlus() {
        return this.cache.vertexDataCache.create(`compression_XPlusFace`, () => {
            const xPlusPath = new Path2(-constants.point, constants.point);
            xPlusPath.addLineTo(constants.point, constants.point);

            const infinityPoints = this.getInfinityPoints();
            for (const point of infinityPoints.xMinusPoints) {
                xPlusPath.addLineTo(-point.x, point.z);
            }

            const vertexData = new PolygonBuilder(xPlusPath).build();
            vertexData.transform(this.cache.matrixCache.rotationX180);
            vertexData.transform(Matrix.RotationZ(Math.PI / 2));
            vertexData.transform(Matrix.Translation(constants.point, 0, 0)); // move to the right

            return this.cloneVertex(vertexData);
        });
    }
    private get face_xMinus() {
        return this.cache.vertexDataCache.create(`compression_xMinusFace`, () => {
            const xMinusPath = new Path2(-constants.point, constants.point);
            xMinusPath.addLineTo(constants.point, constants.point);

            const infinityPoints = this.getInfinityPoints();

            for (const point of infinityPoints.xPlusPoints) {
                xMinusPath.addLineTo(-point.x, point.z);
            }

            const vertexData = new PolygonBuilder(xMinusPath).build();
            vertexData.transform(this.cache.matrixCache.rotationX180);
            vertexData.transform(Matrix.RotationZ(Math.PI + Math.PI / 2));
            vertexData.transform(Matrix.Translation(-constants.point, 0, 0)); // move to the left

            return this.cloneVertex(vertexData);
        });
    }
    private get face_yPlus() {
        return this.cache.vertexDataCache.create(`compression_yPlusFace`, () => {
            const point = constants.point;
            const yPlusPath = new Path2(point, -point);
            yPlusPath.addLineTo(-point, -point);
            yPlusPath.addLineTo(-point, point + (point / constants.scaleFactor)); // long right
            yPlusPath.addLineTo(point, point + (point / constants.scaleFactor)); // long left

            const vertexData = new PolygonBuilder(yPlusPath).build();

            vertexData.transform(Matrix.RotationZ(2 * Math.PI));
            vertexData.transform(Matrix.Translation(0, constants.point, 0)); // move to the back

            return this.cloneVertex(vertexData);
        });
    }
    private get face_yMinus() {
        return this.cache.vertexDataCache.create(`compression_yMinusFace`, () => {
            const point = constants.point;
            const yMinusPath = new Path2(point, -point);
            yMinusPath.addLineTo(-point, -point);
            yMinusPath.addLineTo(-point, point + (point / constants.scaleFactor)); // long right
            yMinusPath.addLineTo(point, point + (point / constants.scaleFactor)); // long left

            const vertexData = new PolygonBuilder(yMinusPath).build();

            vertexData.transform(Matrix.RotationZ(Math.PI));
            vertexData.transform(Matrix.Translation(0, -constants.point, 0)); // move to the back

            return this.cloneVertex(vertexData);
        });
    }
    private get face_top() {
        return this.cache.vertexDataCache.create(`compression_topFace`, () => {
            const vertexData = cloneVertexData(this.face_base);

            vertexData.transform(this.cache.matrixCache.rotationY90);

            return this.cloneVertex(vertexData);
        });
    }
    private get face_bottom_infinite() {
        return this.cache.vertexDataCache.create(`compression_bottomFace`, () => {
            const bottomPath = new Path2(-this.boxSizeHalf, this.boxSizeHalf);
            const infinityPoints = this.getInfinityPoints();

            for (const point of infinityPoints.xPlusPoints) {
                bottomPath.addLineTo(-point.z, -point.x);
            }


            const vertexData = CreateRibbonVertexData({
                pathArray: [infinityPoints.xPlusPoints, infinityPoints.xMinusPoints],
            });

            vertexData.transform(this.cache.matrixCache.rotationZ90);
            vertexData.transform(this.cache.matrixCache.rotationY180);

            return this.cloneVertex(vertexData);
        });
    }

    private get boxSizeHalf() {
        return constants.boxSize / 2;
    }

    private cloneVertex(vertexData: VertexData): VertexData {
        const _vertexData = new VertexData();
        _vertexData.positions = vertexData.positions;
        _vertexData.indices = vertexData.indices;
        _vertexData.normals = vertexData.normals;

        return _vertexData;
    }
}
