import { Injectable } from '@angular/core';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Separator } from '@profis-engineering/pe-ui-common/entities/code-lists/separator';
import { ApplicationSettingsCommon, GeneralValue, IGeneralApplicationSettingsBase, UserSettings as UserSettingsCommon } from '@profis-engineering/pe-ui-common/entities/user-settings';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Controls3dSettings, UserSettingsServiceBase } from '@profis-engineering/pe-ui-common/services/user-settings.common';
import debounce from 'lodash-es/debounce';
import { AppUnitPropertyId, DataService, Unit } from './data.service';
import { designTypes } from './design.service';

export interface GeneralValueRequired<TValue extends number | string | boolean> {
    value: TValue;
}

// TODO FILIP: fix modularization
interface UserSettingsServiceBaseHack extends UserSettingsServiceBase<UserSettings> {
    originalValues: UserSettings;
    copySettingsValues(cloneData: UserSettings, data: UserSettings): void;
}

export interface UserSettings extends UserSettingsCommon<object, object, ApplicationSettingsCommon<IGeneralApplicationSettingsBase, object>, object> {
    sp: {
        tourSeen: GeneralValueRequired<boolean>;
        displayOptions: {
            strength: {
                zones: GeneralValueRequired<boolean>;
                zonesDimensions: GeneralValueRequired<boolean>;
                zonesNumbering: GeneralValueRequired<boolean>;
                concreteDimensions: GeneralValueRequired<boolean>;
                transparentConcrete: GeneralValueRequired<boolean>;
                anchorSpacingDimensions: GeneralValueRequired<boolean>;
                anchorEdgeDistanceDimensions: GeneralValueRequired<boolean>;

            };
            punch: {
                concreteDimensions: GeneralValueRequired<boolean>;
                transparentConcrete: GeneralValueRequired<boolean>;
                criticalPerimeter: GeneralValueRequired<boolean>;
                strengtheningElementSpacingDimensions: GeneralValueRequired<boolean>;
                strengtheningElementEdgeDistanceDimensions: GeneralValueRequired<boolean>;
                openingNumbering: GeneralValueRequired<boolean>;
                openingDimensions: GeneralValueRequired<boolean>;
            };
        };
        quickStart: {
            strength: {
                regionId: GeneralValueRequired<number>;
                readonly resolvedRegionId: number | undefined;
                designStandardId: GeneralValueRequired<number>;
                length: GeneralValueRequired<UnitType>;
                area: GeneralValueRequired<UnitType>;
                stress: GeneralValueRequired<UnitType>;
                force: GeneralValueRequired<UnitType>;
                moment: GeneralValueRequired<UnitType>;
                temperature: GeneralValueRequired<UnitType>;
                forcePerLength: GeneralValueRequired<UnitType>;
                density: GeneralValueRequired<UnitType>;
                areaPerLength: GeneralValueRequired<UnitType>;
            };
            punch: {
                regionId: GeneralValueRequired<number>;
                readonly resolvedRegionId: number | undefined;
                designStandardId: GeneralValueRequired<number>;
                length: GeneralValueRequired<UnitType>;
                area: GeneralValueRequired<UnitType>;
                stress: GeneralValueRequired<UnitType>;
                force: GeneralValueRequired<UnitType>;
                moment: GeneralValueRequired<UnitType>;
                temperature: GeneralValueRequired<UnitType>;
                forcePerLength: GeneralValueRequired<UnitType>;
                density: GeneralValueRequired<UnitType>;
                areaPerLength: GeneralValueRequired<UnitType>;
            };
        };
    };
}

export class UserSettingsSP {
    public tourSeen = new GeneralValue<boolean>(null);
    public displayOptions = {
        strength: {
            zones: new GeneralValue<boolean>(true),
            zonesDimensions: new GeneralValue<boolean>(true),
            zonesNumbering: new GeneralValue<boolean>(true),
            concreteDimensions: new GeneralValue<boolean>(true),
            transparentConcrete: new GeneralValue<boolean>(true),
            anchorSpacingDimensions: new GeneralValue<boolean>(true),
            anchorEdgeDistanceDimensions: new GeneralValue<boolean>(true)
        },
        punch: {
            transparentConcrete: new GeneralValue<boolean>(true),
            concreteDimensions: new GeneralValue<boolean>(true),
            criticalPerimeter: new GeneralValue<boolean>(true),
            strengtheningElementSpacingDimensions: new GeneralValue<boolean>(true),
            strengtheningElementEdgeDistanceDimensions: new GeneralValue<boolean>(true),
            openingNumbering: new GeneralValue<boolean>(true),
            openingDimensions: new GeneralValue<boolean>(true)
        }
    };
    public quickStart = {
        strength: {
            regionId: new GeneralValue<number>(null),
            designStandardId: new GeneralValue<number>(null),
            length: new GeneralValue<UnitType>(null),
            area: new GeneralValue<UnitType>(null),
            stress: new GeneralValue<UnitType>(null),
            force: new GeneralValue<UnitType>(null),
            moment: new GeneralValue<UnitType>(null),
            temperature: new GeneralValue<UnitType>(null),
            forcePerLength: new GeneralValue<UnitType>(null),
            density: new GeneralValue<UnitType>(null),
            areaPerLength: new GeneralValue<UnitType>(null)
        },
        punch: {
            regionId: new GeneralValue<number>(null),
            designStandardId: new GeneralValue<number>(null),
            length: new GeneralValue<UnitType>(null),
            area: new GeneralValue<UnitType>(null),
            stress: new GeneralValue<UnitType>(null),
            force: new GeneralValue<UnitType>(null),
            moment: new GeneralValue<UnitType>(null),
            temperature: new GeneralValue<UnitType>(null),
            forcePerLength: new GeneralValue<UnitType>(null),
            density: new GeneralValue<UnitType>(null),
            areaPerLength: new GeneralValue<UnitType>(null)
        }
    };

    // helper values
    constructor(
        dataService: DataService,
        userSettingsService: UserSettingsService
    ) {
        // TODO FILIP: fix pe-ui so it ignores functions and getters
        Object.defineProperty(this.quickStart.strength, 'resolvedRegionId', {
            get: () => this.getResolvedRegionId(this.quickStart.strength.regionId.value, dataService, userSettingsService)
        });
        Object.defineProperty(this.quickStart.punch, 'resolvedRegionId', {
            get: () => this.getResolvedRegionId(this.quickStart.punch.regionId.value, dataService, userSettingsService)
        });
    }

    private getResolvedRegionId(
        regionId: SpecialRegion | number | null | undefined,
        dataService: DataService,
        userSettingsService: UserSettingsService
    ): number | undefined {
        if (regionId == null || regionId == SpecialRegion.None as number) {
            return undefined;
        }

        if (regionId == SpecialRegion.Default as number) {
            return dataService.regionsById[userSettingsService.settings.application.general.regionId.value ?? 0]?.id;
        }

        return dataService.regionsById[regionId]?.id;
    }
}

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {
    private baseService!: UserSettingsServiceBase<UserSettings>;

    public debounceSave = debounce(() => this.save() as unknown as undefined, 500);

    public setBaseService(baseService: UserSettingsServiceBase<UserSettings>): void {
        this.baseService = baseService;
    }

    public get settings(): UserSettings {
        return this.baseService.settings;
    }

    public save(): Promise<void> {
        return this.baseService.save();
    }

    public getCommonRegionById(regionId: number): CommonRegion {
        return this.baseService.getCommonRegionById(regionId);
    }

    public isSectionCollapsed(settingsControlId?: number | string): boolean {
        return this.baseService.isSectionCollapsed(settingsControlId);
    }

    public setSectionCollapsed(settingsControlId?: number | string, collapsed?: boolean): void {
        this.baseService.setSectionCollapsed(settingsControlId, collapsed);
    }

    public getThousandsSeparator(): Separator {
        return this.baseService.getThousandsSeparator();
    }

    public getDecimalSeparator(): Separator {
        return this.baseService.getDecimalSeparator();
    }

    public get controls3dSettings(): Controls3dSettings {
        return this.baseService.controls3dSettings;
    }

    public init(dataService: DataService): void {
        this.initPunch(dataService);
        this.initStrength(dataService);

        // other settings
        if (this.settings.sp.tourSeen.value == null) {
            this.settings.sp.tourSeen.value = false;
        }

        // TODO FILIP: mark as not changed
        const baseServiceHack = this.baseService as UserSettingsServiceBaseHack;

        if (baseServiceHack.originalValues != null) {
            baseServiceHack.originalValues.sp = baseServiceHack.originalValues.sp ?? {};
            baseServiceHack.copySettingsValues(baseServiceHack.originalValues.sp as unknown as UserSettings, this.settings.sp as unknown as UserSettings);
        }
    }

    public get globalRegion(): CommonRegion {
        return this.getCommonRegionById(this.settings.application.general.regionId.value ?? 0);
    }

    private initStrength(dataService: DataService): void {
        // quick start
        const quickStart = this.settings.sp.quickStart.strength;
        const dataUnits = dataService.units;

        if (quickStart.regionId.value == null || !(quickStart.regionId.value in dataService.strengthRegionsById)) {
            quickStart.regionId.value = SpecialRegion.Default;
        }

        const regionId = quickStart.resolvedRegionId;

        if (regionId != null) {
            const designStandardPropertyDetail = dataService.getPropertyDetail('designStandardId', { designTypeId: designTypes.strength.id, regionId });
            if (quickStart.designStandardId.value == null || !(quickStart.designStandardId.value in (designStandardPropertyDetail.allowedValues ?? []))) {
                quickStart.designStandardId.value = designStandardPropertyDetail.defaultValue;
            }

            this.initUnit(dataService, regionId, quickStart.length, dataUnits.lengthById, 'unitLength');
            this.initUnit(dataService, regionId, quickStart.area, dataUnits.areaById, 'unitArea');
            this.initUnit(dataService, regionId, quickStart.stress, dataUnits.stressById, 'unitStress');
            this.initUnit(dataService, regionId, quickStart.force, dataUnits.forceById, 'unitForce');
            this.initUnit(dataService, regionId, quickStart.moment, dataUnits.momentById, 'unitMoment');
            this.initUnit(dataService, regionId, quickStart.temperature, dataUnits.temperatureById, 'unitTemperature');
            this.initUnit(dataService, regionId, quickStart.forcePerLength, dataUnits.forcePerLengthById, 'unitForcePerLength');
            this.initUnit(dataService, regionId, quickStart.density, dataUnits.densityById, 'unitDensity');
            this.initUnit(dataService, regionId, quickStart.areaPerLength, dataUnits.areaPerLengthById, 'unitAreaPerLength');
        }

        // display options
        const displayOptions = this.settings.sp.displayOptions.strength;
        displayOptions.zones.value = this.toBoolean(displayOptions.zones.value);
        displayOptions.zonesDimensions.value = this.toBoolean(displayOptions.zonesDimensions.value);
        displayOptions.zonesNumbering.value = this.toBoolean(displayOptions.zonesNumbering.value);
        displayOptions.concreteDimensions.value = this.toBoolean(displayOptions.concreteDimensions.value);
        displayOptions.transparentConcrete.value = this.toBoolean(displayOptions.transparentConcrete.value);
        displayOptions.anchorSpacingDimensions.value = this.toBoolean(displayOptions.anchorSpacingDimensions.value);
        displayOptions.anchorEdgeDistanceDimensions.value = this.toBoolean(displayOptions.anchorEdgeDistanceDimensions.value);
    }

    private initPunch(dataService: DataService): void {
        // quick start
        const quickStart = this.settings.sp.quickStart.punch;
        const dataUnits = dataService.units;

        if (quickStart.regionId.value == null || !(quickStart.regionId.value in dataService.punchRegionsById)) {
            quickStart.regionId.value = SpecialRegion.Default;
        }

        const regionId = quickStart.resolvedRegionId;

        if (regionId != null) {
            const designStandardPropertyDetail = dataService.getPropertyDetail('designStandardId', { designTypeId: designTypes.punch.id, regionId });
            if (quickStart.designStandardId.value == null || !(quickStart.designStandardId.value in (designStandardPropertyDetail.allowedValues ?? []))) {
                quickStart.designStandardId.value = designStandardPropertyDetail.defaultValue;
            }

            this.initUnit(dataService, regionId, quickStart.length, dataUnits.lengthById, 'unitLength');
            this.initUnit(dataService, regionId, quickStart.area, dataUnits.areaById, 'unitArea');
            this.initUnit(dataService, regionId, quickStart.stress, dataUnits.stressById, 'unitStress');
            this.initUnit(dataService, regionId, quickStart.force, dataUnits.forceById, 'unitForce');
            this.initUnit(dataService, regionId, quickStart.moment, dataUnits.momentById, 'unitMoment');
            this.initUnit(dataService, regionId, quickStart.temperature, dataUnits.temperatureById, 'unitTemperature');
            this.initUnit(dataService, regionId, quickStart.forcePerLength, dataUnits.forcePerLengthById, 'unitForcePerLength');
            this.initUnit(dataService, regionId, quickStart.density, dataUnits.densityById, 'unitDensity');
            this.initUnit(dataService, regionId, quickStart.areaPerLength, dataUnits.areaPerLengthById, 'unitAreaPerLength');
        }

        // display options
        const displayOptions = this.settings.sp.displayOptions.punch;
        displayOptions.transparentConcrete.value = this.toBoolean(displayOptions.transparentConcrete.value);
        displayOptions.concreteDimensions.value = this.toBoolean(displayOptions.concreteDimensions.value);
        displayOptions.criticalPerimeter.value = this.toBoolean(displayOptions.criticalPerimeter.value);
        displayOptions.strengtheningElementSpacingDimensions.value = this.toBoolean(displayOptions.strengtheningElementSpacingDimensions.value);
        displayOptions.strengtheningElementEdgeDistanceDimensions.value = this.toBoolean(displayOptions.strengtheningElementEdgeDistanceDimensions.value);
        displayOptions.openingNumbering.value = this.toBoolean(displayOptions.openingNumbering.value);
        displayOptions.openingDimensions.value = this.toBoolean(displayOptions.openingDimensions.value);
    }

    private initUnit(dataService: DataService, regionId: number, userSettingsUnit: GeneralValueRequired<UnitType>, dataUnitById: Record<number, Unit>, propertyUnit: AppUnitPropertyId): void {
        if (userSettingsUnit.value == null || !(userSettingsUnit.value in dataUnitById)) {
            userSettingsUnit.value = dataService.getPropertyDetail(propertyUnit, { regionId }).defaultValue ?? UnitType.None;
        }
    }

    private toBoolean(value: unknown): boolean {
        return value === true;
    }
}
