import {
    Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, OnChanges, OnInit
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { L10nDirective } from '../../../directives/l10n.directive';
import { GenericUtilizationResults } from '../../../services/design.service';
import { LocalizationService } from '../../../services/localization.service';
import { UserSettingsService } from '../../../services/user-settings.service';
import { includeSprites } from '../../../sprites';
import { TabType } from '../enums/tab-type';
import { UtilizationItemComponent } from '../utilization-item/utilization-item.component';

@Component({
    selector: 'app-utilization-panel',
    templateUrl: './utilization-panel.component.html',
    styleUrls: ['./utilization-panel.component.scss'],
    imports: [
        NgbTooltip,
        L10nDirective,
        UtilizationItemComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UtilizationPanelComponent implements OnInit, OnChanges {
    @Input()
    public componentId?: string;

    @Input()
    public utilizations: GenericUtilizationResults | undefined;

    public baseHeader = 'SP.Utilizations';
    public showNewLabel = false;
    public utilizationsAvailable = true;
    public TabType: Record<keyof typeof TabType, TabType> = {
        Primary: TabType.Primary,
        Secondary: TabType.Secondary
    };

    constructor(
        private readonly localization: LocalizationService,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly userSettingsService: UserSettingsService,
    ) { }

    public ngOnChanges(): void {
        this.updateVisibility();
    }

    public ngOnInit(): void {
        if (!this.componentId) {
            this.componentId = 'utilization-panel';
        }

        includeSprites(this.elementRef.nativeElement,
            'sprite-tab-supplementary-reinforcement',
            'sprite-arrow-up',
            'sprite-arrow-down',
            'sprite-tab-base-material'
        );

        this.updateVisibility();
    }

    private updateVisibility() {
        if (this.utilizations == null || this.utilizations.sections.length === 0) {
            this.utilizationsAvailable = false;
        }
        else {
            this.utilizationsAvailable = true;
        }
    }

    public getComponentId(postfix: string, sectionIndex?: number, detailGroupIndex?: number, detailIndex?: number) {
        let prefix = this.componentId;

        if (sectionIndex != null) {
            prefix += `-section${sectionIndex + 1}`;
        }

        if (detailGroupIndex != null) {
            prefix += `-detailgroup${detailGroupIndex + 1}`;
        }

        if (detailIndex != null) {
            prefix += `-detail${detailIndex + 1}`;
        }

        return postfix != '' ? `${prefix}-${postfix}` : prefix;
    }

    public typeClass(tabType: TabType): string {
        return tabType == TabType.Secondary ? 'secondary-type' : '';
    }

    public translate(key: string | undefined) {
        if (key == undefined) {
            return undefined;
        }

        return this.localization.getString(key);
    }

    public getPropertyTitle(key: string | undefined, utilizationVariable: string | undefined) {
        if (key == undefined) {
            return undefined;
        }

        if (utilizationVariable === undefined || utilizationVariable === null) {
            return this.localization.getString(key);
        }

        return this.localization.getString(key) + ', ' + utilizationVariable;
    }

    public getUtilizationVariable(key: string | undefined) {
        if (key == undefined) {
            return undefined;
        }

        return key;
    }

    public roundValue(value: number) {
        if (value == Number.MAX_VALUE) {
            return Infinity;
        }

        return Math.ceil(value);
    }

    public showPercentBar(unitGroup: UnitGroup): boolean {
        return unitGroup == UnitGroup.Percentage;
    }

    public toNumber(input: string | number | boolean): number {
        const num = Number(input);
        if (isNaN(num)) {
          return 0;
        }
        return num;
    }

    public showNumberContainer(unitGroup: UnitGroup): boolean {
        return unitGroup != UnitGroup.Percentage;
    }

    public getIsCollapsed(id: string): boolean {
        return this.userSettingsService.isSectionCollapsed(id);
    }

    public onCollapseChanged(collapsed: boolean, id: string) {
        this.userSettingsService.setSectionCollapsed(id, collapsed);
    }

    public showPrimaryTab() {
        return false;
    }
}
