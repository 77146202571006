$color-hilti: #d2051e;
$color-chilli: #a80015;
$color-grape: #671c3e;
$color-cappuccino: #d7cebe;
$color-licorice: #111;
$color-eggplant: #261d24;
$color-gray: #999;
$color-lime: #f4ffe8;
$color-green: #009900;
$color-coal: #413c41;
$color-iron: #524f53;
$color-metal: #ccc;
$color-silver: #fafafa; // CR: did this change on zeplin?
$color-white: #fff;
$color-yellow: #ffaf00;
$color-almost-black: #222222;
$color-blue-link: #0645AD;

// colors not defined on zeplin
$color-0xd9534f: #d9534f;
$color-0xddd: #ddd;
$color-0x887f6e: #887f6e;   // dark grey
$color-0xb2aba5: #b2aba5;
$color-0xf3f1ec: #f3f1ec;
$color-0xeeb24e: #EEB24E;
$color-0x4a90e2: #4a90e2;
$color-0xeeeeee: #eeeeee;
$color-0x3fb550: #3fb550;
$color-0x6E6761: #6E6761;
$color-0xee0011: #ee0011;
$color-0x19af37: #19af37;
$color-0xDDD8C9: #DDD8C9;
$color-0x1b221c22: #1b221c22;
$color-0x1b221c05: #1b221c05;
$color-0xebebeb: #ebebeb;
$color-black: #000000;

// Hilti-styled colors
$color-0xf3f0eb: #f3f0eb; // light grey
$color-0xebe6de: #ebe6de; // medium grey
$color-0xd7ceba: #d7ceba; // medium grey
$color-0x671a3d: #671a3d; // dark red

// Using http://www.perbang.dk/ for color names
$color-cornflower-bluish-white: #f3fbff;
$color-dark-blue-violetish-gray: #504e53;
$color-light-gray: #f3f2f0;
$color-light-gambogeish-gray: #e7e5e2;
$color-gambogeish-gray: #a0998b;
$color-medium-gray: #bab9ba;
$color-light-yellowish-gray: #d8d8d3;
$color-amberish-gray: #cfccc5;
$color-light-tangeloish-gray: #e4e1df;
$color-light-malachite-greenish-gray: #d8f4df;
$color-dark-malachite-green: #117a26;

// Bootstrap
$color-bs-border: #dee2e6;