@use '@profis-engineering/pe-ui-common/styles/colors.scss' as *;
@use '@profis-engineering/pe-ui-common/styles/dimensions.scss' as *;
@use '@profis-engineering/pe-ui-common/styles/section.scss' as *;

.row {
    display: flex;

    .section-column {
        border-right: 1px solid #ccc;
        flex: 1;
        min-width: 0;
        padding: 0 16px;
    }
}

.units {
    margin-bottom: 0;

    .control-row {
        margin: 0 -16px 0 0;
        align-items: flex-end;
        flex-wrap: wrap;
        flex-direction: row;

        .control-column {
            width: 20%;
            padding-right: 16px;
            margin-bottom: 16px;
        }
    }

    .control-row-single-column {
        align-items: flex-end;
    }
}

.data {
    margin-bottom: 0;

    .content {
        .sub-title {
            margin: 0 0 16px 0;
        }

        .data-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            .name-container {
                display: flex;
                flex-direction: column;
                flex: 1;

                .name {
                    font-weight: bold;
                }

                .description {
                    color: $color-gray;
                }
            }
        }
    }
}

.section {
    margin-bottom: 0;

    .title-strike {
        height: 32px;
        line-height: 32px;
        margin-bottom: 4px;
        font-weight: 700;
        color: $color-iron;
        position: relative;
        overflow: hidden;

        .section-collapse {
            border: none;
            background: none;
            padding: 0;
            position: absolute;
            right: 0;
            z-index: 10;
            background-color: white;
        }

        &.no-margin {
            margin-bottom: 0;
        }

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            top: 50%;
            margin-left: 8px;
            border-bottom: $separator-line-height solid $color-metal;
        }
    }

    .control-row {
        .control-column {
            width: calc(20% - 16px)
        }
    }

    .hidden {
        display: none;
    }

    .local-regulations-disclaimer {
        flex: 1;
        background: $color-light-gray;
        color: $color-eggplant;
        border: 1px solid $color-medium-gray;
        padding: 10px;
        margin: 0 0 18px 0;
    }

    &.no-margin {
        margin-bottom: 0;
    }
}

.design-standard-note {
    margin-top: 6px;
    margin-left: 2px;
    margin-right: 2px;
    color: #999;
}
